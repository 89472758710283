import { Wizard } from '@/components/base/wizard';
import { CryptoCurrencySelect } from './crypto-currency-select';
import { CryptoDetailsForm } from './crypto-details-form';

export function CryptoStep({
  handleBack,
  onFinish,
  loading,
}: {
  handleBack?: () => void;
  onFinish: () => void;
  loading: boolean;
}): JSX.Element {
  const steps = [
    {
      id: 1,
      label: 'Selected Method',
      component: <CryptoCurrencySelect onBack={handleBack} />,
    },
    {
      id: 2,
      label: 'Selected Method',
      component: <CryptoDetailsForm />,
    },
  ];
  return <Wizard onFinish={onFinish} loading={loading} steps={steps} />;
}
