import { Divider } from '@/components/base/divider';
import MenuItem from '@/components/base/menu/menu-item';
import { useCurrentUser } from '@/monevis-platform/context/current-user-context';
import {
  faCreditCard,
  faLock,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { useRouterState } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { UploadAvatar } from './update-avatar';

export function SettingsSidebar(): JSX.Element {
  const router = useRouterState();
  const data = useCurrentUser();
  const { firstName, lastName, email } = data.me;
  const { t } = useTranslation();

  function checkMenuItemActive(to: string): boolean {
    if (router.location.pathname === to) {
      return true;
    }
    return false;
  }
  return (
    <div className="w-full max-w-none border-r-0 border-default-gray-200 dark:border-default-gray-800 md:max-w-80 md:border-r">
      <div className="p-4">
        <div className="mb-6 flex-col items-center text-default-gray-950 dark:text-white md:flex">
          <UploadAvatar />

          <div className="hidden flex-col gap-1 text-center md:flex">
            <span className="text-xl">
              {firstName} {lastName}
            </span>
            <span className="text-sm">{email}</span>
          </div>
        </div>
        <Divider className="hidden bg-default-gray-300 dark:bg-default-gray-800 md:block" />
        <nav className="mt-6">
          <ul className="flex flex-col gap-3">
            <MenuItem
              className="rounded-default"
              active={checkMenuItemActive('/dashboard/settings/edit-profile')}
              icon={faUser}
              to="/dashboard/settings/edit-profile"
            >
              {t('settings.editProfile')}
            </MenuItem>
            <MenuItem
              className="rounded-default"
              active={checkMenuItemActive('/dashboard/settings/edit-password')}
              icon={faLock}
              to="/dashboard/settings/edit-password"
            >
              {t('settings.editPassword')}
            </MenuItem>
            <MenuItem
              className="rounded-default"
              active={checkMenuItemActive(
                '/dashboard/settings/billing-history',
              )}
              icon={faCreditCard}
              to="/dashboard/settings/billing-history"
            >
              {t('settings.orderHistory')}
            </MenuItem>
          </ul>
        </nav>
      </div>
    </div>
  );
}
