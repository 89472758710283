import { AuthLayout } from '@/components/(unathenticated)/layout/auth.layout';
import { PageWrapper } from '@/components/base/page-wrapper';
import { ForgottenPassword } from '@/monevis-platform/components/(unauthenticated/forgotten-password';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute(
  '/(unathenticated)/auth/_layout/forgotten-password',
)({
  beforeLoad: async ({ context }) => {
    if (context.auth.isAuthenticated) {
      throw redirect({
        to: '/',
      });
    }
  },
  component: () => (
    <PageWrapper title="Forgotten password - Monevis">
      <LoginPage />
    </PageWrapper>
  ),
});

function LoginPage(): JSX.Element {
  const { t } = useTranslation();
  return (
    <AuthLayout
      sideContent={
        <div className="absolute left-0 top-0 h-full w-full max-w-[50vw] text-[35px] text-primary">
          <div className="px-[5vw] pt-[10vh]">
            <h1 className="text-4xl font-light text-white">
              {t('forgottenPasswordPage.sideContentTitle')}
            </h1>
          </div>
        </div>
      }
    >
      <ForgottenPassword />
    </AuthLayout>
  );
}
