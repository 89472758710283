import CopyTool from '@/components/base/copytool';
import { Input } from '@/components/base/form/input';
import { TextField } from '@/components/base/form/text-field';
import { Loading } from '@/components/base/loading';
import { PageHeading } from '@/components/base/page-heading';
import Section from '@/components/base/section';
import MetatraderDownload from '@/components/metatrader-download';
import { useGetBrokerAccountQuery } from '@graphql/index';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';

export function AccountDetails({
  accountId,
}: {
  accountId: string;
}): JSX.Element {
  const { t } = useTranslation();
  const { data, loading } = useGetBrokerAccountQuery({
    variables: { id: accountId },
    skip: !accountId,
  });

  if (loading) {
    return <></>;
  }

  invariant(data, `[${accountId}] Broker account data must be defined`);
  const { login, server, masterPassword, createdAt, investorPassword } =
    data.brokerAccount;
  if (loading) {
    return (
      <Loading size="big" className="text-default-gray-950 dark:text-white" />
    );
  }

  return (
    <Section variant="secondary">
      <div className="flex w-full flex-col items-start justify-center gap-4 text-2xl">
        <div className="w-full">
          <PageHeading className="mb-2" size="xxlarge">
            {t('accountDetail.tradingLoginInfo')}
          </PageHeading>
          <div className="flex flex-col gap-4 ">
            <CopyTool
              variant="secondary"
              label={t('accountDetail.serverName')}
              text={server}
            />
            <CopyTool
              variant="secondary"
              label={t('accountDetail.login')}
              text={login}
            />
            <CopyTool
              variant="secondary"
              label={t('accountDetail.tradingPassword')}
              text={masterPassword}
              hidden
            />
            <TextField
              labelClassName="dark:text-white text-default-gray-950"
              className="w-full"
              label={t('accountDetail.investorReadonlyPassword')}
            >
              <Input readOnly variant="secondary" value={investorPassword} />
            </TextField>
          </div>
        </div>
        <MetatraderDownload className="mt-5 xl:mt-10" />

        <div className="w-full">
          <PageHeading className="mb-2" size="xxlarge">
            {t('accountDetail.general')}
          </PageHeading>
          <TextField
            labelClassName="dark:text-white text-default-gray-950"
            className="w-full"
            label={t('accountDetail.dateOpened')}
          >
            <Input
              readOnly
              variant="secondary"
              value={format(parseISO(createdAt), 'MMMM d, yyyy')}
              data-testid="date-input"
            />
          </TextField>
        </div>
      </div>
    </Section>
  );
}
