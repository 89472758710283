import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import { usePageLayout } from '@/context/layout-context';
import { TradingAccountsListWithData } from '@/monevis-platform/components/(authenticated)/trading-accounts/trading-accounts-list';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/trading-accounts',
)({
  component: () => (
    <PageWrapper title="Trading Accounts - Monevis">
      <TradingAccountsPage />
    </PageWrapper>
  ),
});
function TradingAccountsPage(): JSX.Element {
  const { t } = useTranslation();
  usePageLayout({ title: t('tradingAccountsPage.title') });

  return (
    <ScreenContainer>
      <TradingAccountsListWithData />
    </ScreenContainer>
  );
}

export default TradingAccountsPage;
