import { PageHeading } from '@/components/base/page-heading';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/base/tabs';
import { JoinSocialsSection } from '@/monevis-platform/components/(authenticated)/calendar/help-section';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ResourceCard from './resource-card';
import { useResourceObject } from './resource-config';
import ToggleSection from './resource-toggle';

function ResourcesList(): JSX.Element {
  const resourceObject = useResourceObject();
  const [openSections, setOpenSections] = useState(resourceObject.openSections);
  const { t } = useTranslation();

  function toggleContent(section: string): void {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  }

  return (
    <div className="flex h-full flex-col justify-between gap-20">
      <div className="flex flex-col gap-7">
        <Tabs defaultValue={t('All')}>
          <div className="mx-4">
            <TabsList>
              {Object.keys(resourceObject.tabsData).map((tab) => (
                <TabsTrigger key={tab} value={tab}>
                  {tab}
                </TabsTrigger>
              ))}
            </TabsList>
          </div>

          {Object.keys(resourceObject.tabsData).map((tab) => (
            <TabsContent key={tab} value={tab}>
              {tab === t('All') ? (
                <div className="flex flex-col gap-4">
                  {Object.keys(openSections).map((section) => (
                    <React.Fragment key={section}>
                      <ToggleSection
                        sectionName={section}
                        isOpen={openSections[section]}
                        toggleContent={toggleContent}
                      />
                      {openSections[section] && (
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                          {resourceObject.tabsData[section]?.length ? (
                            resourceObject.tabsData[section].map((resource) => (
                              <ResourceCard
                                key={resource.id}
                                youtube={resource.youtube}
                                title={resource.title}
                                type={resource.type}
                                pages={resource.pages}
                                mb={resource.mb}
                                duration={resource.duration}
                                application={resource.application}
                                downloadUrl={resource.downloadUrl}
                              />
                            ))
                          ) : (
                            <p className="px-5 text-start text-lg font-semibold text-gray-500">
                              {t('resource.nodata')}
                            </p>
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              ) : (
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                  {resourceObject.tabsData[tab].length ? (
                    resourceObject.tabsData[tab].map((resource) => (
                      <ResourceCard
                        key={resource.id}
                        youtube={resource.youtube}
                        title={resource.title}
                        type={resource.type}
                        pages={resource.pages}
                        mb={resource.mb}
                        duration={resource.duration}
                        application={resource.application}
                        downloadUrl={resource.downloadUrl}
                      />
                    ))
                  ) : (
                    <p className="px-5 text-start text-lg font-semibold text-gray-500">
                      {t('resource.nodata')}
                    </p>
                  )}
                </div>
              )}
            </TabsContent>
          ))}
        </Tabs>
      </div>
      <JoinSocialsSection>
        <PageHeading subheading={t('resource.subheading')}>
          {t('resource.join')}
        </PageHeading>
      </JoinSocialsSection>
    </div>
  );
}

export default ResourcesList;
