/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison  -- KYC DISABLED*/
import { Button } from '@/components/base/button';
import { FormCheckbox } from '@/components/base/form/form-checkbox';
import { FormField } from '@/components/base/form/form-field';
import { Input } from '@/components/base/form/input';
import { PageHeading } from '@/components/base/page-heading';
import Section from '@/components/base/section';
import { useCurrentUser } from '@/monevis-platform/context/current-user-context';
import {
  faCircleCheck,
  faCircleXmark,
  faClock,
  faLock,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KycStatus, useRequestKycMutation } from '@graphql/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { enqueueSnackbar } from 'notistack';
import { type FormEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const kycSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string(),
  declaration: z.literal<boolean>(true),
});

export function KYCFullPage() {
  const { t } = useTranslation();
  const { me } = useCurrentUser();
  const [requestKycMutation, { loading }] = useRequestKycMutation();

  type Kyc = z.infer<typeof kycSchema>;

  const methods = useForm<Kyc>({
    resolver: zodResolver(kycSchema),
    mode: 'onChange',
    values: {
      firstName: me.firstName,
      lastName: me.lastName,
      declaration: false,
    },
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  async function onSubmit(_values: Kyc) {
    try {
      const response = await requestKycMutation();

      if (response.data?.requestKyc?.url) {
        window.location.href = response.data?.requestKyc?.url;
      }
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar({
          message: e.message,
          variant: 'error',
        });
      }
    }
  }

  const { handleSubmit } = methods;

  function handleClickSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    return void (async () => {
      handleSubmit((values) => {
        onSubmit(values);
      })();
    })();
  }

  return (
    <Section variant="secondary" className="mx-auto flex max-w-4xl">
      {/* <AccountDetails accountId={data?.order.brokerAccount?.login} /> */}
      <div className="flex w-full flex-col gap-2">
        <div>
          <PageHeading subheading={t('kyc.subTitle')} className="">
            {t('kyc.title')}
          </PageHeading>
        </div>

        {me.kycStatus === KycStatus.Rejected && (
          <div className="mt-4 flex items-center gap-4 text-red-500">
            <div>
              <FontAwesomeIcon icon={faCircleXmark} className="text-8xl" />
            </div>
            <div>{t('kyc.kycFailed')}</div>
          </div>
        )}

        {me.kycStatus === KycStatus.Verified && (
          <div className="mt-4 flex items-center gap-4 text-gray-500">
            <div>
              <FontAwesomeIcon icon={faClock} className="text-8xl" />
            </div>
            <div>{t('kyc.kycVerified')}</div>
          </div>
        )}

        {me.kycStatus === KycStatus.Approved && (
          <div className="mt-4 flex items-center gap-4 text-green-500">
            <div>
              <FontAwesomeIcon icon={faCircleCheck} className="text-8xl" />
            </div>
            <div>{t('kyc.kycApproved')}</div>
          </div>
        )}

        {me.kycStatus !== KycStatus.Rejected &&
          me.kycStatus !== KycStatus.Verified && (
            <div className="mt-4">
              <FormProvider {...methods}>
                <form onSubmit={handleClickSubmit}>
                  <div className="flex flex-col gap-6">
                    <fieldset>
                      <FormField
                        labelClassName="dark:text-white text-default-gray-950"
                        label={t('settings.firstNameLabel')}
                        name="firstName"
                      >
                        <Input
                          data-testid="firstName"
                          defaultValue={me.firstName}
                          variant="secondary"
                          disabled
                          placeholder={t('settings.firstNamePlaceholder')}
                        />
                      </FormField>
                    </fieldset>

                    <fieldset>
                      <FormField
                        labelClassName="dark:text-white text-default-gray-950"
                        label={t('settings.lastNameLabel')}
                        name="lastName"
                      >
                        <Input
                          data-testid="lastName"
                          defaultValue={me.lastName}
                          variant="secondary"
                          disabled
                          placeholder={t('settings.lastNamePlaceholder')}
                        />
                      </FormField>
                    </fieldset>

                    <fieldset>
                      <FormCheckbox
                        data-testid="declaration-checkbox"
                        name="declaration"
                      >
                        <span>
                          <span>{t('kyc.declaration')}</span>
                        </span>
                      </FormCheckbox>
                    </fieldset>
                  </div>

                  <div className="mt-4 flex w-full justify-end">
                    <Button
                      as="button"
                      // disabled={!isDirty}
                      className="w-full md:w-fit"
                      loading={loading}
                      type="submit"
                      icon={<FontAwesomeIcon icon={faLock} />}
                      data-testid="submitButton"
                    >
                      {t('kyc.startVerification')}
                    </Button>
                  </div>
                </form>
              </FormProvider>
            </div>
          )}
      </div>
    </Section>
  );
}
