interface AppConfig {
  DISCORD_AUTH_URL: string;
  GOOGLE_AUTH_URL: string;
  TWITTER_AUTH_URL: string;
  TERMS_CONDITIONS_URL: string;
  PRIVACY_POLICY_URL: string;
  REFUND_POLICY_URL: string;
  GRAPHQL_ENDPOINT: string;
  AFF_API_SIGN: string;
  GTM_ID: string;
}

const AppConfig: AppConfig = {
  GOOGLE_AUTH_URL:
    (import.meta.env.VITE_GOOGLE_AUTH_URL as string) ||
    window.ENV?.GOOGLE_AUTH_URL ||
    '',
  DISCORD_AUTH_URL:
    (import.meta.env.VITE_DISCORD_AUTH_URL as string) ||
    window.ENV?.DISCORD_AUTH_URL ||
    '',
  TWITTER_AUTH_URL:
    (import.meta.env.VITE_TWITTER_AUTH_URL as string) ||
    window.ENV?.TWITTER_AUTH_URL ||
    '',
  TERMS_CONDITIONS_URL:
    (import.meta.env.VITE_TERMS_CONDITIONS_URL as string) ||
    window.ENV?.TERMS_CONDITIONS_URL ||
    '',
  PRIVACY_POLICY_URL:
    (import.meta.env.VITE_PRIVACY_POLICY_URL as string) ||
    window.ENV?.PRIVACY_POLICY_URL ||
    '',
  REFUND_POLICY_URL:
    (import.meta.env.VITE_REFUND_POLICY_URL as string) ||
    window.ENV?.PRIVACY_POLICY_URL ||
    '',
  GRAPHQL_ENDPOINT:
    (import.meta.env.VITE_GRAPHQL_ENDPOINT as string) ??
    window.ENV?.GRAPHQL_ENDPOINT ??
    'http://localhost:8000/graphql',
  AFF_API_SIGN:
    (import.meta.env.VITE_AFF_API_SIGN as string) ??
    window.ENV?.AFF_API_SIGN ??
    'http://localhost:8000/graphql',
  GTM_ID: (import.meta.env.VITE_GTM_ID as string) ?? window.ENV?.GTM_ID ?? '',
};

export default AppConfig;
