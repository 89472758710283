import { PageHeading } from '@/components/base/page-heading';

import { Button } from '@/components/base/button';
import { TextField } from '@/components/base/form/text-field';
import { RadioGroup, RadioGroupItem } from '@/components/base/radio-group';
import Section from '@/components/base/section';
import { CryptoCurrency } from '@/components/icons';
import React, { cloneElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { type Crypto, cryptoDataMap } from './crypto-details-form';

function CryptoRadioItem({
  value,
  children,
}: {
  value: string;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <RadioGroupItem
      className="w-fit max-w-28 items-center px-7 py-4 dark:border-default-gray-600 dark:bg-default-gray-900/20"
      value={value}
      data-testid={value}
    >
      <div className="flex w-full flex-col items-center justify-center self-center">
        {children}
      </div>
    </RadioGroupItem>
  );
}

export function CryptoCurrencySelect({
  handleNext,
  onBack,
}: {
  handleNext?: () => void;
  onBack?: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const watchCrypto = watch('crypto') as Crypto;

  return (
    <div
      data-testid="crypto-currency-select"
      className="flex w-full justify-center"
    >
      <Section
        variant="secondary"
        className="w-fit min-w-[350px] md:min-w-[630px]"
      >
        <div className="flex w-full flex-col gap-6">
          <div className="flex items-center gap-4">
            <CryptoCurrency />
            <PageHeading className="mb-0">
              {t('requestPayout.cryptoMethodTitle')}
            </PageHeading>
          </div>

          <TextField
            labelClassName="dark:text-white text-default-gray-950"
            className="w-full"
            label={t('requestPayout.cryptoMethodLabel')}
          >
            <RadioGroup
              onValueChange={(v: Crypto) => {
                setValue('crypto', v);
              }}
              defaultValue={watchCrypto}
              className="flex w-full flex-wrap gap-11"
            >
              {Object.values(cryptoDataMap).map((item) => {
                return (
                  item.value && (
                    <CryptoRadioItem key={item.value} value={item.value}>
                      {item.icon &&
                        cloneElement(item.icon, { width: 55, height: 55 })}
                      <span className="text-sm">{item.label}</span>
                      {item.warning && (
                        <span className="text-xs text-red-600">
                          {item.warning}
                        </span>
                      )}
                    </CryptoRadioItem>
                  )
                );
              })}
            </RadioGroup>
          </TextField>

          <div className="flex w-full justify-between gap-10">
            <Button
              onClick={() => {
                onBack && onBack();
              }}
              variant="secondary"
              size="sm"
              className="w-full"
              type="button"
            >
              {t('requestPayout.buttonBack')}
            </Button>
            <Button
              disabled={!watchCrypto}
              onClick={handleNext}
              className="w-full"
              size="sm"
            >
              {t('requestPayout.buttonNext')}
            </Button>
          </div>
        </div>
      </Section>
    </div>
  );
}
