import { Wizard } from '@/components/base/wizard';
import {
  useSetWithdrawalPaymentMethodMutation,
  WithdrawalPaymentMethod,
} from '@graphql/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from '@tanstack/react-router';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { CryptoStep } from './crypto';
import { Crypto } from './crypto-details-form';
import { RiseDetailsForm } from './rise-details-form';
import { SelectPayoutMethod } from './select-method';

export function getCryptoAddressValidation({
  address,
  currency,
}: {
  address: string;
  currency: Crypto;
}): boolean {
  if (currency === Crypto.USDC) {
    const tetherRegex = /^0x[a-fA-F0-9]{40}$/;
    return tetherRegex.test(address);
  }
  return false;
}

const risePayoutMethodSchema = z.object({
  targetAddress: z.string().min(1, { message: 'Email is required' }).email(),
  displayName: z.string().min(1, { message: 'Display name is required' }),
  crypto: z.nativeEnum(Crypto).optional(),
});

const cryptoPayoutMethodSchema = z
  .object({
    crypto: z.nativeEnum(Crypto),
    targetAddress: z.string().min(1, { message: 'Crypto address is required' }),
    targetAddressConfirm: z
      .string()
      .min(1, { message: 'Crypto address confirmation is required' }),
    displayName: z.string().min(1, { message: 'Display name is required' }),
  })
  .refine(
    (data) => {
      return getCryptoAddressValidation({
        address: data.targetAddress,
        currency: data.crypto,
      });
    },
    {
      message: 'Crypto address is invalid',
      path: ['targetAddress'],
    },
  )
  .refine(
    (data) => {
      return data.targetAddress === data.targetAddressConfirm;
    },
    {
      message: 'Crypto address must match',
      path: ['targetAddressConfirm'],
    },
  );

export function AddPayoutMethod(): JSX.Element {
  const [selectedMethod, setSelectedMethod] =
    useState<WithdrawalPaymentMethod | null>();
  const [setPaymentMethod, { loading }] =
    useSetWithdrawalPaymentMethodMutation();

  const navigate = useNavigate();
  const schema =
    selectedMethod === WithdrawalPaymentMethod.DirectCrypto
      ? cryptoPayoutMethodSchema
      : risePayoutMethodSchema;

  type PayoutMethodSchema = z.infer<typeof schema>;
  const methods = useForm<PayoutMethodSchema>({
    resolver: zodResolver(schema),
    mode: 'onChange',
  });

  const { handleSubmit } = methods;

  function handleFinish(): void {
    handleSubmit(async (values) => {
      const metadata =
        selectedMethod === WithdrawalPaymentMethod.DirectCrypto
          ? (values.crypto as string)
          : null;
      try {
        await setPaymentMethod({
          variables: {
            withdrawalPaymentMethodInput: {
              paymentMethod: selectedMethod,
              targetAddress: values.targetAddress,
              paymentMethodMetadata: JSON.stringify({ network: metadata }),
              displayName: values.displayName,
            },
          },
        });
        navigate({ to: '/dashboard/request-payout' });
      } catch (e) {
        enqueueSnackbar({
          message: 'error',
          variant: 'error',
        });
      }
    })();
  }
  const steps = [
    {
      id: 1,
      label: 'Select Payout Method',
      component: <SelectPayoutMethod onChange={setSelectedMethod} />,
    },
    {
      id: 2,
      label: 'Selected Method',
      component:
        selectedMethod === WithdrawalPaymentMethod.Rise ? (
          <RiseDetailsForm loading={loading} />
        ) : (
          <CryptoStep loading={loading} onFinish={handleFinish} />
        ),
    },
  ];

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Wizard onFinish={handleFinish} loading={loading} steps={steps} />
      </form>
    </FormProvider>
  );
}
