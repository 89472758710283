/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LayoutImport } from './routes/_layout'
import { Route as IndexImport } from './routes/index'
import { Route as authenticatedOrderStatusIndexImport } from './routes/(authenticated)/order-status/index'
import { Route as unathenticatedAuthLayoutImport } from './routes/(unathenticated)/auth/_layout'
import { Route as globalTrialLayoutImport } from './routes/(global)/trial/_layout'
import { Route as globalOrderLayoutImport } from './routes/(global)/order/_layout'
import { Route as authenticatedDashboardLayoutImport } from './routes/(authenticated)/dashboard/_layout'
import { Route as unathenticatedAuthSplatIndexImport } from './routes/(unathenticated)/auth/$/index'
import { Route as globalTrialLayoutIndexImport } from './routes/(global)/trial/_layout/index'
import { Route as globalOrderLayoutIndexImport } from './routes/(global)/order/_layout/index'
import { Route as authenticatedDashboardSplatIndexImport } from './routes/(authenticated)/dashboard/$/index'
import { Route as unathenticatedAuthLayoutRegistrationImport } from './routes/(unathenticated)/auth/_layout/registration'
import { Route as unathenticatedAuthLayoutLoginImport } from './routes/(unathenticated)/auth/_layout/login'
import { Route as unathenticatedAuthLayoutInvalidLinkImport } from './routes/(unathenticated)/auth/_layout/invalid-link'
import { Route as unathenticatedAuthLayoutForgottenPasswordImport } from './routes/(unathenticated)/auth/_layout/forgotten-password'
import { Route as unathenticatedAuthLayoutChangePasswordImport } from './routes/(unathenticated)/auth/_layout/change-password'
import { Route as globalOrderLayoutOrderIdImport } from './routes/(global)/order/_layout/$order-id'
import { Route as globalOauth2CallbackHandlerImport } from './routes/(global)/oauth2/callback.$handler'
import { Route as authenticatedDashboardLayoutUserCertificatesImport } from './routes/(authenticated)/dashboard/_layout/user-certificates'
import { Route as authenticatedDashboardLayoutTradingAccountsImport } from './routes/(authenticated)/dashboard/_layout/trading-accounts'
import { Route as authenticatedDashboardLayoutResourcesImport } from './routes/(authenticated)/dashboard/_layout/resources'
import { Route as authenticatedDashboardLayoutRankingsImport } from './routes/(authenticated)/dashboard/_layout/rankings'
import { Route as authenticatedDashboardLayoutPayoutImport } from './routes/(authenticated)/dashboard/_layout/payout'
import { Route as authenticatedDashboardLayoutImpersonateImport } from './routes/(authenticated)/dashboard/_layout/impersonate'
import { Route as authenticatedDashboardLayoutIdentityImport } from './routes/(authenticated)/dashboard/_layout/identity'
import { Route as authenticatedDashboardLayoutHomeImport } from './routes/(authenticated)/dashboard/_layout/home'
import { Route as authenticatedDashboardLayoutCalendarImport } from './routes/(authenticated)/dashboard/_layout/calendar'
import { Route as authenticatedDashboardLayoutAffiliateImport } from './routes/(authenticated)/dashboard/_layout/affiliate'
import { Route as authenticatedDashboardLayoutLayoutSettingsImport } from './routes/(authenticated)/dashboard/_layout/_layout-settings'
import { Route as authenticatedDashboardLayoutRequestPayoutIndexImport } from './routes/(authenticated)/dashboard/_layout/request-payout/index'
import { Route as globalOrderLayoutPayOrderIdImport } from './routes/(global)/order/_layout/pay.$order-id'
import { Route as authenticatedDashboardLayoutRequestPayoutMethodImport } from './routes/(authenticated)/dashboard/_layout/request-payout/method'
import { Route as authenticatedDashboardLayoutAccountsTerminalImport } from './routes/(authenticated)/dashboard/_layout/accounts.terminal'
import { Route as authenticatedDashboardLayoutAccountsAccountIdImport } from './routes/(authenticated)/dashboard/_layout/accounts.$account-id'
import { Route as authenticatedDashboardLayoutAccountsTerminalAccountIdImport } from './routes/(authenticated)/dashboard/_layout/accounts.terminal.$account-id'
import { Route as authenticatedDashboardLayoutAccountsRestartAccountIdImport } from './routes/(authenticated)/dashboard/_layout/accounts.restart.$account-id'
import { Route as authenticatedDashboardLayoutAccountsDetailAccountIdImport } from './routes/(authenticated)/dashboard/_layout/accounts.detail.$account-id'
import { Route as authenticatedDashboardLayoutLayoutSettingsSettingsEditProfileImport } from './routes/(authenticated)/dashboard/_layout/_layout-settings/settings/edit-profile'
import { Route as authenticatedDashboardLayoutLayoutSettingsSettingsEditPasswordImport } from './routes/(authenticated)/dashboard/_layout/_layout-settings/settings/edit-password'
import { Route as authenticatedDashboardLayoutLayoutSettingsSettingsBillingHistoryImport } from './routes/(authenticated)/dashboard/_layout/_layout-settings/settings/billing-history'

// Create Virtual Routes

const unathenticatedAuthImport = createFileRoute('/(unathenticated)/auth')()
const globalTrialImport = createFileRoute('/(global)/trial')()
const globalOrderImport = createFileRoute('/(global)/order')()
const authenticatedDashboardImport = createFileRoute(
  '/(authenticated)/dashboard',
)()

// Create/Update Routes

const LayoutRoute = LayoutImport.update({
  id: '/_layout',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const unathenticatedAuthRoute = unathenticatedAuthImport.update({
  id: '/(unathenticated)/auth',
  path: '/auth',
  getParentRoute: () => rootRoute,
} as any)

const globalTrialRoute = globalTrialImport.update({
  id: '/(global)/trial',
  path: '/trial',
  getParentRoute: () => rootRoute,
} as any)

const globalOrderRoute = globalOrderImport.update({
  id: '/(global)/order',
  path: '/order',
  getParentRoute: () => rootRoute,
} as any)

const authenticatedDashboardRoute = authenticatedDashboardImport.update({
  id: '/(authenticated)/dashboard',
  path: '/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const authenticatedOrderStatusIndexRoute =
  authenticatedOrderStatusIndexImport.update({
    id: '/(authenticated)/order-status/',
    path: '/order-status/',
    getParentRoute: () => rootRoute,
  } as any)

const unathenticatedAuthLayoutRoute = unathenticatedAuthLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => unathenticatedAuthRoute,
} as any)

const globalTrialLayoutRoute = globalTrialLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => globalTrialRoute,
} as any)

const globalOrderLayoutRoute = globalOrderLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => globalOrderRoute,
} as any)

const authenticatedDashboardLayoutRoute =
  authenticatedDashboardLayoutImport.update({
    id: '/_layout',
    getParentRoute: () => authenticatedDashboardRoute,
  } as any)

const unathenticatedAuthSplatIndexRoute =
  unathenticatedAuthSplatIndexImport.update({
    id: '/$/',
    path: '/$/',
    getParentRoute: () => unathenticatedAuthRoute,
  } as any)

const globalTrialLayoutIndexRoute = globalTrialLayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => globalTrialLayoutRoute,
} as any)

const globalOrderLayoutIndexRoute = globalOrderLayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => globalOrderLayoutRoute,
} as any)

const authenticatedDashboardSplatIndexRoute =
  authenticatedDashboardSplatIndexImport.update({
    id: '/$/',
    path: '/$/',
    getParentRoute: () => authenticatedDashboardRoute,
  } as any)

const unathenticatedAuthLayoutRegistrationRoute =
  unathenticatedAuthLayoutRegistrationImport.update({
    id: '/registration',
    path: '/registration',
    getParentRoute: () => unathenticatedAuthLayoutRoute,
  } as any)

const unathenticatedAuthLayoutLoginRoute =
  unathenticatedAuthLayoutLoginImport.update({
    id: '/login',
    path: '/login',
    getParentRoute: () => unathenticatedAuthLayoutRoute,
  } as any)

const unathenticatedAuthLayoutInvalidLinkRoute =
  unathenticatedAuthLayoutInvalidLinkImport.update({
    id: '/invalid-link',
    path: '/invalid-link',
    getParentRoute: () => unathenticatedAuthLayoutRoute,
  } as any)

const unathenticatedAuthLayoutForgottenPasswordRoute =
  unathenticatedAuthLayoutForgottenPasswordImport.update({
    id: '/forgotten-password',
    path: '/forgotten-password',
    getParentRoute: () => unathenticatedAuthLayoutRoute,
  } as any)

const unathenticatedAuthLayoutChangePasswordRoute =
  unathenticatedAuthLayoutChangePasswordImport.update({
    id: '/change-password',
    path: '/change-password',
    getParentRoute: () => unathenticatedAuthLayoutRoute,
  } as any)

const globalOrderLayoutOrderIdRoute = globalOrderLayoutOrderIdImport.update({
  id: '/$order-id',
  path: '/$order-id',
  getParentRoute: () => globalOrderLayoutRoute,
} as any)

const globalOauth2CallbackHandlerRoute =
  globalOauth2CallbackHandlerImport.update({
    id: '/(global)/oauth2/callback/$handler',
    path: '/oauth2/callback/$handler',
    getParentRoute: () => rootRoute,
  } as any)

const authenticatedDashboardLayoutUserCertificatesRoute =
  authenticatedDashboardLayoutUserCertificatesImport.update({
    id: '/user-certificates',
    path: '/user-certificates',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const authenticatedDashboardLayoutTradingAccountsRoute =
  authenticatedDashboardLayoutTradingAccountsImport.update({
    id: '/trading-accounts',
    path: '/trading-accounts',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const authenticatedDashboardLayoutResourcesRoute =
  authenticatedDashboardLayoutResourcesImport.update({
    id: '/resources',
    path: '/resources',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const authenticatedDashboardLayoutRankingsRoute =
  authenticatedDashboardLayoutRankingsImport.update({
    id: '/rankings',
    path: '/rankings',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const authenticatedDashboardLayoutPayoutRoute =
  authenticatedDashboardLayoutPayoutImport.update({
    id: '/payout',
    path: '/payout',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const authenticatedDashboardLayoutImpersonateRoute =
  authenticatedDashboardLayoutImpersonateImport.update({
    id: '/impersonate',
    path: '/impersonate',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const authenticatedDashboardLayoutIdentityRoute =
  authenticatedDashboardLayoutIdentityImport.update({
    id: '/identity',
    path: '/identity',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const authenticatedDashboardLayoutHomeRoute =
  authenticatedDashboardLayoutHomeImport.update({
    id: '/home',
    path: '/home',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const authenticatedDashboardLayoutCalendarRoute =
  authenticatedDashboardLayoutCalendarImport.update({
    id: '/calendar',
    path: '/calendar',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const authenticatedDashboardLayoutAffiliateRoute =
  authenticatedDashboardLayoutAffiliateImport.update({
    id: '/affiliate',
    path: '/affiliate',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const authenticatedDashboardLayoutLayoutSettingsRoute =
  authenticatedDashboardLayoutLayoutSettingsImport.update({
    id: '/_layout-settings',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const authenticatedDashboardLayoutRequestPayoutIndexRoute =
  authenticatedDashboardLayoutRequestPayoutIndexImport.update({
    id: '/request-payout/',
    path: '/request-payout/',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const globalOrderLayoutPayOrderIdRoute =
  globalOrderLayoutPayOrderIdImport.update({
    id: '/pay/$order-id',
    path: '/pay/$order-id',
    getParentRoute: () => globalOrderLayoutRoute,
  } as any)

const authenticatedDashboardLayoutRequestPayoutMethodRoute =
  authenticatedDashboardLayoutRequestPayoutMethodImport.update({
    id: '/request-payout/method',
    path: '/request-payout/method',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const authenticatedDashboardLayoutAccountsTerminalRoute =
  authenticatedDashboardLayoutAccountsTerminalImport.update({
    id: '/accounts/terminal',
    path: '/accounts/terminal',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const authenticatedDashboardLayoutAccountsAccountIdRoute =
  authenticatedDashboardLayoutAccountsAccountIdImport.update({
    id: '/accounts/$account-id',
    path: '/accounts/$account-id',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const authenticatedDashboardLayoutAccountsTerminalAccountIdRoute =
  authenticatedDashboardLayoutAccountsTerminalAccountIdImport.update({
    id: '/$account-id',
    path: '/$account-id',
    getParentRoute: () => authenticatedDashboardLayoutAccountsTerminalRoute,
  } as any)

const authenticatedDashboardLayoutAccountsRestartAccountIdRoute =
  authenticatedDashboardLayoutAccountsRestartAccountIdImport.update({
    id: '/accounts/restart/$account-id',
    path: '/accounts/restart/$account-id',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const authenticatedDashboardLayoutAccountsDetailAccountIdRoute =
  authenticatedDashboardLayoutAccountsDetailAccountIdImport.update({
    id: '/accounts/detail/$account-id',
    path: '/accounts/detail/$account-id',
    getParentRoute: () => authenticatedDashboardLayoutRoute,
  } as any)

const authenticatedDashboardLayoutLayoutSettingsSettingsEditProfileRoute =
  authenticatedDashboardLayoutLayoutSettingsSettingsEditProfileImport.update({
    id: '/settings/edit-profile',
    path: '/settings/edit-profile',
    getParentRoute: () => authenticatedDashboardLayoutLayoutSettingsRoute,
  } as any)

const authenticatedDashboardLayoutLayoutSettingsSettingsEditPasswordRoute =
  authenticatedDashboardLayoutLayoutSettingsSettingsEditPasswordImport.update({
    id: '/settings/edit-password',
    path: '/settings/edit-password',
    getParentRoute: () => authenticatedDashboardLayoutLayoutSettingsRoute,
  } as any)

const authenticatedDashboardLayoutLayoutSettingsSettingsBillingHistoryRoute =
  authenticatedDashboardLayoutLayoutSettingsSettingsBillingHistoryImport.update(
    {
      id: '/settings/billing-history',
      path: '/settings/billing-history',
      getParentRoute: () => authenticatedDashboardLayoutLayoutSettingsRoute,
    } as any,
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_layout': {
      id: '/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LayoutImport
      parentRoute: typeof rootRoute
    }
    '/(authenticated)/dashboard': {
      id: '/(authenticated)/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof authenticatedDashboardImport
      parentRoute: typeof rootRoute
    }
    '/(authenticated)/dashboard/_layout': {
      id: '/(authenticated)/dashboard/_layout'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof authenticatedDashboardLayoutImport
      parentRoute: typeof authenticatedDashboardRoute
    }
    '/(global)/order': {
      id: '/(global)/order'
      path: '/order'
      fullPath: '/order'
      preLoaderRoute: typeof globalOrderImport
      parentRoute: typeof rootRoute
    }
    '/(global)/order/_layout': {
      id: '/(global)/order/_layout'
      path: '/order'
      fullPath: '/order'
      preLoaderRoute: typeof globalOrderLayoutImport
      parentRoute: typeof globalOrderRoute
    }
    '/(global)/trial': {
      id: '/(global)/trial'
      path: '/trial'
      fullPath: '/trial'
      preLoaderRoute: typeof globalTrialImport
      parentRoute: typeof rootRoute
    }
    '/(global)/trial/_layout': {
      id: '/(global)/trial/_layout'
      path: '/trial'
      fullPath: '/trial'
      preLoaderRoute: typeof globalTrialLayoutImport
      parentRoute: typeof globalTrialRoute
    }
    '/(unathenticated)/auth': {
      id: '/(unathenticated)/auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof unathenticatedAuthImport
      parentRoute: typeof rootRoute
    }
    '/(unathenticated)/auth/_layout': {
      id: '/(unathenticated)/auth/_layout'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof unathenticatedAuthLayoutImport
      parentRoute: typeof unathenticatedAuthRoute
    }
    '/(authenticated)/order-status/': {
      id: '/(authenticated)/order-status/'
      path: '/order-status'
      fullPath: '/order-status'
      preLoaderRoute: typeof authenticatedOrderStatusIndexImport
      parentRoute: typeof rootRoute
    }
    '/(authenticated)/dashboard/_layout/_layout-settings': {
      id: '/(authenticated)/dashboard/_layout/_layout-settings'
      path: ''
      fullPath: '/dashboard'
      preLoaderRoute: typeof authenticatedDashboardLayoutLayoutSettingsImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(authenticated)/dashboard/_layout/affiliate': {
      id: '/(authenticated)/dashboard/_layout/affiliate'
      path: '/affiliate'
      fullPath: '/dashboard/affiliate'
      preLoaderRoute: typeof authenticatedDashboardLayoutAffiliateImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(authenticated)/dashboard/_layout/calendar': {
      id: '/(authenticated)/dashboard/_layout/calendar'
      path: '/calendar'
      fullPath: '/dashboard/calendar'
      preLoaderRoute: typeof authenticatedDashboardLayoutCalendarImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(authenticated)/dashboard/_layout/home': {
      id: '/(authenticated)/dashboard/_layout/home'
      path: '/home'
      fullPath: '/dashboard/home'
      preLoaderRoute: typeof authenticatedDashboardLayoutHomeImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(authenticated)/dashboard/_layout/identity': {
      id: '/(authenticated)/dashboard/_layout/identity'
      path: '/identity'
      fullPath: '/dashboard/identity'
      preLoaderRoute: typeof authenticatedDashboardLayoutIdentityImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(authenticated)/dashboard/_layout/impersonate': {
      id: '/(authenticated)/dashboard/_layout/impersonate'
      path: '/impersonate'
      fullPath: '/dashboard/impersonate'
      preLoaderRoute: typeof authenticatedDashboardLayoutImpersonateImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(authenticated)/dashboard/_layout/payout': {
      id: '/(authenticated)/dashboard/_layout/payout'
      path: '/payout'
      fullPath: '/dashboard/payout'
      preLoaderRoute: typeof authenticatedDashboardLayoutPayoutImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(authenticated)/dashboard/_layout/rankings': {
      id: '/(authenticated)/dashboard/_layout/rankings'
      path: '/rankings'
      fullPath: '/dashboard/rankings'
      preLoaderRoute: typeof authenticatedDashboardLayoutRankingsImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(authenticated)/dashboard/_layout/resources': {
      id: '/(authenticated)/dashboard/_layout/resources'
      path: '/resources'
      fullPath: '/dashboard/resources'
      preLoaderRoute: typeof authenticatedDashboardLayoutResourcesImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(authenticated)/dashboard/_layout/trading-accounts': {
      id: '/(authenticated)/dashboard/_layout/trading-accounts'
      path: '/trading-accounts'
      fullPath: '/dashboard/trading-accounts'
      preLoaderRoute: typeof authenticatedDashboardLayoutTradingAccountsImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(authenticated)/dashboard/_layout/user-certificates': {
      id: '/(authenticated)/dashboard/_layout/user-certificates'
      path: '/user-certificates'
      fullPath: '/dashboard/user-certificates'
      preLoaderRoute: typeof authenticatedDashboardLayoutUserCertificatesImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(global)/oauth2/callback/$handler': {
      id: '/(global)/oauth2/callback/$handler'
      path: '/oauth2/callback/$handler'
      fullPath: '/oauth2/callback/$handler'
      preLoaderRoute: typeof globalOauth2CallbackHandlerImport
      parentRoute: typeof rootRoute
    }
    '/(global)/order/_layout/$order-id': {
      id: '/(global)/order/_layout/$order-id'
      path: '/$order-id'
      fullPath: '/order/$order-id'
      preLoaderRoute: typeof globalOrderLayoutOrderIdImport
      parentRoute: typeof globalOrderLayoutImport
    }
    '/(unathenticated)/auth/_layout/change-password': {
      id: '/(unathenticated)/auth/_layout/change-password'
      path: '/change-password'
      fullPath: '/auth/change-password'
      preLoaderRoute: typeof unathenticatedAuthLayoutChangePasswordImport
      parentRoute: typeof unathenticatedAuthLayoutImport
    }
    '/(unathenticated)/auth/_layout/forgotten-password': {
      id: '/(unathenticated)/auth/_layout/forgotten-password'
      path: '/forgotten-password'
      fullPath: '/auth/forgotten-password'
      preLoaderRoute: typeof unathenticatedAuthLayoutForgottenPasswordImport
      parentRoute: typeof unathenticatedAuthLayoutImport
    }
    '/(unathenticated)/auth/_layout/invalid-link': {
      id: '/(unathenticated)/auth/_layout/invalid-link'
      path: '/invalid-link'
      fullPath: '/auth/invalid-link'
      preLoaderRoute: typeof unathenticatedAuthLayoutInvalidLinkImport
      parentRoute: typeof unathenticatedAuthLayoutImport
    }
    '/(unathenticated)/auth/_layout/login': {
      id: '/(unathenticated)/auth/_layout/login'
      path: '/login'
      fullPath: '/auth/login'
      preLoaderRoute: typeof unathenticatedAuthLayoutLoginImport
      parentRoute: typeof unathenticatedAuthLayoutImport
    }
    '/(unathenticated)/auth/_layout/registration': {
      id: '/(unathenticated)/auth/_layout/registration'
      path: '/registration'
      fullPath: '/auth/registration'
      preLoaderRoute: typeof unathenticatedAuthLayoutRegistrationImport
      parentRoute: typeof unathenticatedAuthLayoutImport
    }
    '/(authenticated)/dashboard/$/': {
      id: '/(authenticated)/dashboard/$/'
      path: '/$'
      fullPath: '/dashboard/$'
      preLoaderRoute: typeof authenticatedDashboardSplatIndexImport
      parentRoute: typeof authenticatedDashboardImport
    }
    '/(global)/order/_layout/': {
      id: '/(global)/order/_layout/'
      path: '/'
      fullPath: '/order/'
      preLoaderRoute: typeof globalOrderLayoutIndexImport
      parentRoute: typeof globalOrderLayoutImport
    }
    '/(global)/trial/_layout/': {
      id: '/(global)/trial/_layout/'
      path: '/'
      fullPath: '/trial/'
      preLoaderRoute: typeof globalTrialLayoutIndexImport
      parentRoute: typeof globalTrialLayoutImport
    }
    '/(unathenticated)/auth/$/': {
      id: '/(unathenticated)/auth/$/'
      path: '/$'
      fullPath: '/auth/$'
      preLoaderRoute: typeof unathenticatedAuthSplatIndexImport
      parentRoute: typeof unathenticatedAuthImport
    }
    '/(authenticated)/dashboard/_layout/accounts/$account-id': {
      id: '/(authenticated)/dashboard/_layout/accounts/$account-id'
      path: '/accounts/$account-id'
      fullPath: '/dashboard/accounts/$account-id'
      preLoaderRoute: typeof authenticatedDashboardLayoutAccountsAccountIdImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(authenticated)/dashboard/_layout/accounts/terminal': {
      id: '/(authenticated)/dashboard/_layout/accounts/terminal'
      path: '/accounts/terminal'
      fullPath: '/dashboard/accounts/terminal'
      preLoaderRoute: typeof authenticatedDashboardLayoutAccountsTerminalImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(authenticated)/dashboard/_layout/request-payout/method': {
      id: '/(authenticated)/dashboard/_layout/request-payout/method'
      path: '/request-payout/method'
      fullPath: '/dashboard/request-payout/method'
      preLoaderRoute: typeof authenticatedDashboardLayoutRequestPayoutMethodImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(global)/order/_layout/pay/$order-id': {
      id: '/(global)/order/_layout/pay/$order-id'
      path: '/pay/$order-id'
      fullPath: '/order/pay/$order-id'
      preLoaderRoute: typeof globalOrderLayoutPayOrderIdImport
      parentRoute: typeof globalOrderLayoutImport
    }
    '/(authenticated)/dashboard/_layout/request-payout/': {
      id: '/(authenticated)/dashboard/_layout/request-payout/'
      path: '/request-payout'
      fullPath: '/dashboard/request-payout'
      preLoaderRoute: typeof authenticatedDashboardLayoutRequestPayoutIndexImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(authenticated)/dashboard/_layout/_layout-settings/settings/billing-history': {
      id: '/(authenticated)/dashboard/_layout/_layout-settings/settings/billing-history'
      path: '/settings/billing-history'
      fullPath: '/dashboard/settings/billing-history'
      preLoaderRoute: typeof authenticatedDashboardLayoutLayoutSettingsSettingsBillingHistoryImport
      parentRoute: typeof authenticatedDashboardLayoutLayoutSettingsImport
    }
    '/(authenticated)/dashboard/_layout/_layout-settings/settings/edit-password': {
      id: '/(authenticated)/dashboard/_layout/_layout-settings/settings/edit-password'
      path: '/settings/edit-password'
      fullPath: '/dashboard/settings/edit-password'
      preLoaderRoute: typeof authenticatedDashboardLayoutLayoutSettingsSettingsEditPasswordImport
      parentRoute: typeof authenticatedDashboardLayoutLayoutSettingsImport
    }
    '/(authenticated)/dashboard/_layout/_layout-settings/settings/edit-profile': {
      id: '/(authenticated)/dashboard/_layout/_layout-settings/settings/edit-profile'
      path: '/settings/edit-profile'
      fullPath: '/dashboard/settings/edit-profile'
      preLoaderRoute: typeof authenticatedDashboardLayoutLayoutSettingsSettingsEditProfileImport
      parentRoute: typeof authenticatedDashboardLayoutLayoutSettingsImport
    }
    '/(authenticated)/dashboard/_layout/accounts/detail/$account-id': {
      id: '/(authenticated)/dashboard/_layout/accounts/detail/$account-id'
      path: '/accounts/detail/$account-id'
      fullPath: '/dashboard/accounts/detail/$account-id'
      preLoaderRoute: typeof authenticatedDashboardLayoutAccountsDetailAccountIdImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(authenticated)/dashboard/_layout/accounts/restart/$account-id': {
      id: '/(authenticated)/dashboard/_layout/accounts/restart/$account-id'
      path: '/accounts/restart/$account-id'
      fullPath: '/dashboard/accounts/restart/$account-id'
      preLoaderRoute: typeof authenticatedDashboardLayoutAccountsRestartAccountIdImport
      parentRoute: typeof authenticatedDashboardLayoutImport
    }
    '/(authenticated)/dashboard/_layout/accounts/terminal/$account-id': {
      id: '/(authenticated)/dashboard/_layout/accounts/terminal/$account-id'
      path: '/$account-id'
      fullPath: '/dashboard/accounts/terminal/$account-id'
      preLoaderRoute: typeof authenticatedDashboardLayoutAccountsTerminalAccountIdImport
      parentRoute: typeof authenticatedDashboardLayoutAccountsTerminalImport
    }
  }
}

// Create and export the route tree

interface authenticatedDashboardLayoutLayoutSettingsRouteChildren {
  authenticatedDashboardLayoutLayoutSettingsSettingsBillingHistoryRoute: typeof authenticatedDashboardLayoutLayoutSettingsSettingsBillingHistoryRoute
  authenticatedDashboardLayoutLayoutSettingsSettingsEditPasswordRoute: typeof authenticatedDashboardLayoutLayoutSettingsSettingsEditPasswordRoute
  authenticatedDashboardLayoutLayoutSettingsSettingsEditProfileRoute: typeof authenticatedDashboardLayoutLayoutSettingsSettingsEditProfileRoute
}

const authenticatedDashboardLayoutLayoutSettingsRouteChildren: authenticatedDashboardLayoutLayoutSettingsRouteChildren =
  {
    authenticatedDashboardLayoutLayoutSettingsSettingsBillingHistoryRoute:
      authenticatedDashboardLayoutLayoutSettingsSettingsBillingHistoryRoute,
    authenticatedDashboardLayoutLayoutSettingsSettingsEditPasswordRoute:
      authenticatedDashboardLayoutLayoutSettingsSettingsEditPasswordRoute,
    authenticatedDashboardLayoutLayoutSettingsSettingsEditProfileRoute:
      authenticatedDashboardLayoutLayoutSettingsSettingsEditProfileRoute,
  }

const authenticatedDashboardLayoutLayoutSettingsRouteWithChildren =
  authenticatedDashboardLayoutLayoutSettingsRoute._addFileChildren(
    authenticatedDashboardLayoutLayoutSettingsRouteChildren,
  )

interface authenticatedDashboardLayoutAccountsTerminalRouteChildren {
  authenticatedDashboardLayoutAccountsTerminalAccountIdRoute: typeof authenticatedDashboardLayoutAccountsTerminalAccountIdRoute
}

const authenticatedDashboardLayoutAccountsTerminalRouteChildren: authenticatedDashboardLayoutAccountsTerminalRouteChildren =
  {
    authenticatedDashboardLayoutAccountsTerminalAccountIdRoute:
      authenticatedDashboardLayoutAccountsTerminalAccountIdRoute,
  }

const authenticatedDashboardLayoutAccountsTerminalRouteWithChildren =
  authenticatedDashboardLayoutAccountsTerminalRoute._addFileChildren(
    authenticatedDashboardLayoutAccountsTerminalRouteChildren,
  )

interface authenticatedDashboardLayoutRouteChildren {
  authenticatedDashboardLayoutLayoutSettingsRoute: typeof authenticatedDashboardLayoutLayoutSettingsRouteWithChildren
  authenticatedDashboardLayoutAffiliateRoute: typeof authenticatedDashboardLayoutAffiliateRoute
  authenticatedDashboardLayoutCalendarRoute: typeof authenticatedDashboardLayoutCalendarRoute
  authenticatedDashboardLayoutHomeRoute: typeof authenticatedDashboardLayoutHomeRoute
  authenticatedDashboardLayoutIdentityRoute: typeof authenticatedDashboardLayoutIdentityRoute
  authenticatedDashboardLayoutImpersonateRoute: typeof authenticatedDashboardLayoutImpersonateRoute
  authenticatedDashboardLayoutPayoutRoute: typeof authenticatedDashboardLayoutPayoutRoute
  authenticatedDashboardLayoutRankingsRoute: typeof authenticatedDashboardLayoutRankingsRoute
  authenticatedDashboardLayoutResourcesRoute: typeof authenticatedDashboardLayoutResourcesRoute
  authenticatedDashboardLayoutTradingAccountsRoute: typeof authenticatedDashboardLayoutTradingAccountsRoute
  authenticatedDashboardLayoutUserCertificatesRoute: typeof authenticatedDashboardLayoutUserCertificatesRoute
  authenticatedDashboardLayoutAccountsAccountIdRoute: typeof authenticatedDashboardLayoutAccountsAccountIdRoute
  authenticatedDashboardLayoutAccountsTerminalRoute: typeof authenticatedDashboardLayoutAccountsTerminalRouteWithChildren
  authenticatedDashboardLayoutRequestPayoutMethodRoute: typeof authenticatedDashboardLayoutRequestPayoutMethodRoute
  authenticatedDashboardLayoutRequestPayoutIndexRoute: typeof authenticatedDashboardLayoutRequestPayoutIndexRoute
  authenticatedDashboardLayoutAccountsDetailAccountIdRoute: typeof authenticatedDashboardLayoutAccountsDetailAccountIdRoute
  authenticatedDashboardLayoutAccountsRestartAccountIdRoute: typeof authenticatedDashboardLayoutAccountsRestartAccountIdRoute
}

const authenticatedDashboardLayoutRouteChildren: authenticatedDashboardLayoutRouteChildren =
  {
    authenticatedDashboardLayoutLayoutSettingsRoute:
      authenticatedDashboardLayoutLayoutSettingsRouteWithChildren,
    authenticatedDashboardLayoutAffiliateRoute:
      authenticatedDashboardLayoutAffiliateRoute,
    authenticatedDashboardLayoutCalendarRoute:
      authenticatedDashboardLayoutCalendarRoute,
    authenticatedDashboardLayoutHomeRoute:
      authenticatedDashboardLayoutHomeRoute,
    authenticatedDashboardLayoutIdentityRoute:
      authenticatedDashboardLayoutIdentityRoute,
    authenticatedDashboardLayoutImpersonateRoute:
      authenticatedDashboardLayoutImpersonateRoute,
    authenticatedDashboardLayoutPayoutRoute:
      authenticatedDashboardLayoutPayoutRoute,
    authenticatedDashboardLayoutRankingsRoute:
      authenticatedDashboardLayoutRankingsRoute,
    authenticatedDashboardLayoutResourcesRoute:
      authenticatedDashboardLayoutResourcesRoute,
    authenticatedDashboardLayoutTradingAccountsRoute:
      authenticatedDashboardLayoutTradingAccountsRoute,
    authenticatedDashboardLayoutUserCertificatesRoute:
      authenticatedDashboardLayoutUserCertificatesRoute,
    authenticatedDashboardLayoutAccountsAccountIdRoute:
      authenticatedDashboardLayoutAccountsAccountIdRoute,
    authenticatedDashboardLayoutAccountsTerminalRoute:
      authenticatedDashboardLayoutAccountsTerminalRouteWithChildren,
    authenticatedDashboardLayoutRequestPayoutMethodRoute:
      authenticatedDashboardLayoutRequestPayoutMethodRoute,
    authenticatedDashboardLayoutRequestPayoutIndexRoute:
      authenticatedDashboardLayoutRequestPayoutIndexRoute,
    authenticatedDashboardLayoutAccountsDetailAccountIdRoute:
      authenticatedDashboardLayoutAccountsDetailAccountIdRoute,
    authenticatedDashboardLayoutAccountsRestartAccountIdRoute:
      authenticatedDashboardLayoutAccountsRestartAccountIdRoute,
  }

const authenticatedDashboardLayoutRouteWithChildren =
  authenticatedDashboardLayoutRoute._addFileChildren(
    authenticatedDashboardLayoutRouteChildren,
  )

interface authenticatedDashboardRouteChildren {
  authenticatedDashboardLayoutRoute: typeof authenticatedDashboardLayoutRouteWithChildren
  authenticatedDashboardSplatIndexRoute: typeof authenticatedDashboardSplatIndexRoute
}

const authenticatedDashboardRouteChildren: authenticatedDashboardRouteChildren =
  {
    authenticatedDashboardLayoutRoute:
      authenticatedDashboardLayoutRouteWithChildren,
    authenticatedDashboardSplatIndexRoute:
      authenticatedDashboardSplatIndexRoute,
  }

const authenticatedDashboardRouteWithChildren =
  authenticatedDashboardRoute._addFileChildren(
    authenticatedDashboardRouteChildren,
  )

interface globalOrderLayoutRouteChildren {
  globalOrderLayoutOrderIdRoute: typeof globalOrderLayoutOrderIdRoute
  globalOrderLayoutIndexRoute: typeof globalOrderLayoutIndexRoute
  globalOrderLayoutPayOrderIdRoute: typeof globalOrderLayoutPayOrderIdRoute
}

const globalOrderLayoutRouteChildren: globalOrderLayoutRouteChildren = {
  globalOrderLayoutOrderIdRoute: globalOrderLayoutOrderIdRoute,
  globalOrderLayoutIndexRoute: globalOrderLayoutIndexRoute,
  globalOrderLayoutPayOrderIdRoute: globalOrderLayoutPayOrderIdRoute,
}

const globalOrderLayoutRouteWithChildren =
  globalOrderLayoutRoute._addFileChildren(globalOrderLayoutRouteChildren)

interface globalOrderRouteChildren {
  globalOrderLayoutRoute: typeof globalOrderLayoutRouteWithChildren
}

const globalOrderRouteChildren: globalOrderRouteChildren = {
  globalOrderLayoutRoute: globalOrderLayoutRouteWithChildren,
}

const globalOrderRouteWithChildren = globalOrderRoute._addFileChildren(
  globalOrderRouteChildren,
)

interface globalTrialLayoutRouteChildren {
  globalTrialLayoutIndexRoute: typeof globalTrialLayoutIndexRoute
}

const globalTrialLayoutRouteChildren: globalTrialLayoutRouteChildren = {
  globalTrialLayoutIndexRoute: globalTrialLayoutIndexRoute,
}

const globalTrialLayoutRouteWithChildren =
  globalTrialLayoutRoute._addFileChildren(globalTrialLayoutRouteChildren)

interface globalTrialRouteChildren {
  globalTrialLayoutRoute: typeof globalTrialLayoutRouteWithChildren
}

const globalTrialRouteChildren: globalTrialRouteChildren = {
  globalTrialLayoutRoute: globalTrialLayoutRouteWithChildren,
}

const globalTrialRouteWithChildren = globalTrialRoute._addFileChildren(
  globalTrialRouteChildren,
)

interface unathenticatedAuthLayoutRouteChildren {
  unathenticatedAuthLayoutChangePasswordRoute: typeof unathenticatedAuthLayoutChangePasswordRoute
  unathenticatedAuthLayoutForgottenPasswordRoute: typeof unathenticatedAuthLayoutForgottenPasswordRoute
  unathenticatedAuthLayoutInvalidLinkRoute: typeof unathenticatedAuthLayoutInvalidLinkRoute
  unathenticatedAuthLayoutLoginRoute: typeof unathenticatedAuthLayoutLoginRoute
  unathenticatedAuthLayoutRegistrationRoute: typeof unathenticatedAuthLayoutRegistrationRoute
}

const unathenticatedAuthLayoutRouteChildren: unathenticatedAuthLayoutRouteChildren =
  {
    unathenticatedAuthLayoutChangePasswordRoute:
      unathenticatedAuthLayoutChangePasswordRoute,
    unathenticatedAuthLayoutForgottenPasswordRoute:
      unathenticatedAuthLayoutForgottenPasswordRoute,
    unathenticatedAuthLayoutInvalidLinkRoute:
      unathenticatedAuthLayoutInvalidLinkRoute,
    unathenticatedAuthLayoutLoginRoute: unathenticatedAuthLayoutLoginRoute,
    unathenticatedAuthLayoutRegistrationRoute:
      unathenticatedAuthLayoutRegistrationRoute,
  }

const unathenticatedAuthLayoutRouteWithChildren =
  unathenticatedAuthLayoutRoute._addFileChildren(
    unathenticatedAuthLayoutRouteChildren,
  )

interface unathenticatedAuthRouteChildren {
  unathenticatedAuthLayoutRoute: typeof unathenticatedAuthLayoutRouteWithChildren
  unathenticatedAuthSplatIndexRoute: typeof unathenticatedAuthSplatIndexRoute
}

const unathenticatedAuthRouteChildren: unathenticatedAuthRouteChildren = {
  unathenticatedAuthLayoutRoute: unathenticatedAuthLayoutRouteWithChildren,
  unathenticatedAuthSplatIndexRoute: unathenticatedAuthSplatIndexRoute,
}

const unathenticatedAuthRouteWithChildren =
  unathenticatedAuthRoute._addFileChildren(unathenticatedAuthRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof LayoutRoute
  '/dashboard': typeof authenticatedDashboardLayoutLayoutSettingsRouteWithChildren
  '/order': typeof globalOrderLayoutRouteWithChildren
  '/trial': typeof globalTrialLayoutRouteWithChildren
  '/auth': typeof unathenticatedAuthLayoutRouteWithChildren
  '/order-status': typeof authenticatedOrderStatusIndexRoute
  '/dashboard/affiliate': typeof authenticatedDashboardLayoutAffiliateRoute
  '/dashboard/calendar': typeof authenticatedDashboardLayoutCalendarRoute
  '/dashboard/home': typeof authenticatedDashboardLayoutHomeRoute
  '/dashboard/identity': typeof authenticatedDashboardLayoutIdentityRoute
  '/dashboard/impersonate': typeof authenticatedDashboardLayoutImpersonateRoute
  '/dashboard/payout': typeof authenticatedDashboardLayoutPayoutRoute
  '/dashboard/rankings': typeof authenticatedDashboardLayoutRankingsRoute
  '/dashboard/resources': typeof authenticatedDashboardLayoutResourcesRoute
  '/dashboard/trading-accounts': typeof authenticatedDashboardLayoutTradingAccountsRoute
  '/dashboard/user-certificates': typeof authenticatedDashboardLayoutUserCertificatesRoute
  '/oauth2/callback/$handler': typeof globalOauth2CallbackHandlerRoute
  '/order/$order-id': typeof globalOrderLayoutOrderIdRoute
  '/auth/change-password': typeof unathenticatedAuthLayoutChangePasswordRoute
  '/auth/forgotten-password': typeof unathenticatedAuthLayoutForgottenPasswordRoute
  '/auth/invalid-link': typeof unathenticatedAuthLayoutInvalidLinkRoute
  '/auth/login': typeof unathenticatedAuthLayoutLoginRoute
  '/auth/registration': typeof unathenticatedAuthLayoutRegistrationRoute
  '/dashboard/$': typeof authenticatedDashboardSplatIndexRoute
  '/order/': typeof globalOrderLayoutIndexRoute
  '/trial/': typeof globalTrialLayoutIndexRoute
  '/auth/$': typeof unathenticatedAuthSplatIndexRoute
  '/dashboard/accounts/$account-id': typeof authenticatedDashboardLayoutAccountsAccountIdRoute
  '/dashboard/accounts/terminal': typeof authenticatedDashboardLayoutAccountsTerminalRouteWithChildren
  '/dashboard/request-payout/method': typeof authenticatedDashboardLayoutRequestPayoutMethodRoute
  '/order/pay/$order-id': typeof globalOrderLayoutPayOrderIdRoute
  '/dashboard/request-payout': typeof authenticatedDashboardLayoutRequestPayoutIndexRoute
  '/dashboard/settings/billing-history': typeof authenticatedDashboardLayoutLayoutSettingsSettingsBillingHistoryRoute
  '/dashboard/settings/edit-password': typeof authenticatedDashboardLayoutLayoutSettingsSettingsEditPasswordRoute
  '/dashboard/settings/edit-profile': typeof authenticatedDashboardLayoutLayoutSettingsSettingsEditProfileRoute
  '/dashboard/accounts/detail/$account-id': typeof authenticatedDashboardLayoutAccountsDetailAccountIdRoute
  '/dashboard/accounts/restart/$account-id': typeof authenticatedDashboardLayoutAccountsRestartAccountIdRoute
  '/dashboard/accounts/terminal/$account-id': typeof authenticatedDashboardLayoutAccountsTerminalAccountIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof LayoutRoute
  '/dashboard': typeof authenticatedDashboardLayoutLayoutSettingsRouteWithChildren
  '/order': typeof globalOrderLayoutIndexRoute
  '/trial': typeof globalTrialLayoutIndexRoute
  '/auth': typeof unathenticatedAuthLayoutRouteWithChildren
  '/order-status': typeof authenticatedOrderStatusIndexRoute
  '/dashboard/affiliate': typeof authenticatedDashboardLayoutAffiliateRoute
  '/dashboard/calendar': typeof authenticatedDashboardLayoutCalendarRoute
  '/dashboard/home': typeof authenticatedDashboardLayoutHomeRoute
  '/dashboard/identity': typeof authenticatedDashboardLayoutIdentityRoute
  '/dashboard/impersonate': typeof authenticatedDashboardLayoutImpersonateRoute
  '/dashboard/payout': typeof authenticatedDashboardLayoutPayoutRoute
  '/dashboard/rankings': typeof authenticatedDashboardLayoutRankingsRoute
  '/dashboard/resources': typeof authenticatedDashboardLayoutResourcesRoute
  '/dashboard/trading-accounts': typeof authenticatedDashboardLayoutTradingAccountsRoute
  '/dashboard/user-certificates': typeof authenticatedDashboardLayoutUserCertificatesRoute
  '/oauth2/callback/$handler': typeof globalOauth2CallbackHandlerRoute
  '/order/$order-id': typeof globalOrderLayoutOrderIdRoute
  '/auth/change-password': typeof unathenticatedAuthLayoutChangePasswordRoute
  '/auth/forgotten-password': typeof unathenticatedAuthLayoutForgottenPasswordRoute
  '/auth/invalid-link': typeof unathenticatedAuthLayoutInvalidLinkRoute
  '/auth/login': typeof unathenticatedAuthLayoutLoginRoute
  '/auth/registration': typeof unathenticatedAuthLayoutRegistrationRoute
  '/dashboard/$': typeof authenticatedDashboardSplatIndexRoute
  '/auth/$': typeof unathenticatedAuthSplatIndexRoute
  '/dashboard/accounts/$account-id': typeof authenticatedDashboardLayoutAccountsAccountIdRoute
  '/dashboard/accounts/terminal': typeof authenticatedDashboardLayoutAccountsTerminalRouteWithChildren
  '/dashboard/request-payout/method': typeof authenticatedDashboardLayoutRequestPayoutMethodRoute
  '/order/pay/$order-id': typeof globalOrderLayoutPayOrderIdRoute
  '/dashboard/request-payout': typeof authenticatedDashboardLayoutRequestPayoutIndexRoute
  '/dashboard/settings/billing-history': typeof authenticatedDashboardLayoutLayoutSettingsSettingsBillingHistoryRoute
  '/dashboard/settings/edit-password': typeof authenticatedDashboardLayoutLayoutSettingsSettingsEditPasswordRoute
  '/dashboard/settings/edit-profile': typeof authenticatedDashboardLayoutLayoutSettingsSettingsEditProfileRoute
  '/dashboard/accounts/detail/$account-id': typeof authenticatedDashboardLayoutAccountsDetailAccountIdRoute
  '/dashboard/accounts/restart/$account-id': typeof authenticatedDashboardLayoutAccountsRestartAccountIdRoute
  '/dashboard/accounts/terminal/$account-id': typeof authenticatedDashboardLayoutAccountsTerminalAccountIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_layout': typeof LayoutRoute
  '/(authenticated)/dashboard': typeof authenticatedDashboardRouteWithChildren
  '/(authenticated)/dashboard/_layout': typeof authenticatedDashboardLayoutRouteWithChildren
  '/(global)/order': typeof globalOrderRouteWithChildren
  '/(global)/order/_layout': typeof globalOrderLayoutRouteWithChildren
  '/(global)/trial': typeof globalTrialRouteWithChildren
  '/(global)/trial/_layout': typeof globalTrialLayoutRouteWithChildren
  '/(unathenticated)/auth': typeof unathenticatedAuthRouteWithChildren
  '/(unathenticated)/auth/_layout': typeof unathenticatedAuthLayoutRouteWithChildren
  '/(authenticated)/order-status/': typeof authenticatedOrderStatusIndexRoute
  '/(authenticated)/dashboard/_layout/_layout-settings': typeof authenticatedDashboardLayoutLayoutSettingsRouteWithChildren
  '/(authenticated)/dashboard/_layout/affiliate': typeof authenticatedDashboardLayoutAffiliateRoute
  '/(authenticated)/dashboard/_layout/calendar': typeof authenticatedDashboardLayoutCalendarRoute
  '/(authenticated)/dashboard/_layout/home': typeof authenticatedDashboardLayoutHomeRoute
  '/(authenticated)/dashboard/_layout/identity': typeof authenticatedDashboardLayoutIdentityRoute
  '/(authenticated)/dashboard/_layout/impersonate': typeof authenticatedDashboardLayoutImpersonateRoute
  '/(authenticated)/dashboard/_layout/payout': typeof authenticatedDashboardLayoutPayoutRoute
  '/(authenticated)/dashboard/_layout/rankings': typeof authenticatedDashboardLayoutRankingsRoute
  '/(authenticated)/dashboard/_layout/resources': typeof authenticatedDashboardLayoutResourcesRoute
  '/(authenticated)/dashboard/_layout/trading-accounts': typeof authenticatedDashboardLayoutTradingAccountsRoute
  '/(authenticated)/dashboard/_layout/user-certificates': typeof authenticatedDashboardLayoutUserCertificatesRoute
  '/(global)/oauth2/callback/$handler': typeof globalOauth2CallbackHandlerRoute
  '/(global)/order/_layout/$order-id': typeof globalOrderLayoutOrderIdRoute
  '/(unathenticated)/auth/_layout/change-password': typeof unathenticatedAuthLayoutChangePasswordRoute
  '/(unathenticated)/auth/_layout/forgotten-password': typeof unathenticatedAuthLayoutForgottenPasswordRoute
  '/(unathenticated)/auth/_layout/invalid-link': typeof unathenticatedAuthLayoutInvalidLinkRoute
  '/(unathenticated)/auth/_layout/login': typeof unathenticatedAuthLayoutLoginRoute
  '/(unathenticated)/auth/_layout/registration': typeof unathenticatedAuthLayoutRegistrationRoute
  '/(authenticated)/dashboard/$/': typeof authenticatedDashboardSplatIndexRoute
  '/(global)/order/_layout/': typeof globalOrderLayoutIndexRoute
  '/(global)/trial/_layout/': typeof globalTrialLayoutIndexRoute
  '/(unathenticated)/auth/$/': typeof unathenticatedAuthSplatIndexRoute
  '/(authenticated)/dashboard/_layout/accounts/$account-id': typeof authenticatedDashboardLayoutAccountsAccountIdRoute
  '/(authenticated)/dashboard/_layout/accounts/terminal': typeof authenticatedDashboardLayoutAccountsTerminalRouteWithChildren
  '/(authenticated)/dashboard/_layout/request-payout/method': typeof authenticatedDashboardLayoutRequestPayoutMethodRoute
  '/(global)/order/_layout/pay/$order-id': typeof globalOrderLayoutPayOrderIdRoute
  '/(authenticated)/dashboard/_layout/request-payout/': typeof authenticatedDashboardLayoutRequestPayoutIndexRoute
  '/(authenticated)/dashboard/_layout/_layout-settings/settings/billing-history': typeof authenticatedDashboardLayoutLayoutSettingsSettingsBillingHistoryRoute
  '/(authenticated)/dashboard/_layout/_layout-settings/settings/edit-password': typeof authenticatedDashboardLayoutLayoutSettingsSettingsEditPasswordRoute
  '/(authenticated)/dashboard/_layout/_layout-settings/settings/edit-profile': typeof authenticatedDashboardLayoutLayoutSettingsSettingsEditProfileRoute
  '/(authenticated)/dashboard/_layout/accounts/detail/$account-id': typeof authenticatedDashboardLayoutAccountsDetailAccountIdRoute
  '/(authenticated)/dashboard/_layout/accounts/restart/$account-id': typeof authenticatedDashboardLayoutAccountsRestartAccountIdRoute
  '/(authenticated)/dashboard/_layout/accounts/terminal/$account-id': typeof authenticatedDashboardLayoutAccountsTerminalAccountIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/dashboard'
    | '/order'
    | '/trial'
    | '/auth'
    | '/order-status'
    | '/dashboard/affiliate'
    | '/dashboard/calendar'
    | '/dashboard/home'
    | '/dashboard/identity'
    | '/dashboard/impersonate'
    | '/dashboard/payout'
    | '/dashboard/rankings'
    | '/dashboard/resources'
    | '/dashboard/trading-accounts'
    | '/dashboard/user-certificates'
    | '/oauth2/callback/$handler'
    | '/order/$order-id'
    | '/auth/change-password'
    | '/auth/forgotten-password'
    | '/auth/invalid-link'
    | '/auth/login'
    | '/auth/registration'
    | '/dashboard/$'
    | '/order/'
    | '/trial/'
    | '/auth/$'
    | '/dashboard/accounts/$account-id'
    | '/dashboard/accounts/terminal'
    | '/dashboard/request-payout/method'
    | '/order/pay/$order-id'
    | '/dashboard/request-payout'
    | '/dashboard/settings/billing-history'
    | '/dashboard/settings/edit-password'
    | '/dashboard/settings/edit-profile'
    | '/dashboard/accounts/detail/$account-id'
    | '/dashboard/accounts/restart/$account-id'
    | '/dashboard/accounts/terminal/$account-id'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/dashboard'
    | '/order'
    | '/trial'
    | '/auth'
    | '/order-status'
    | '/dashboard/affiliate'
    | '/dashboard/calendar'
    | '/dashboard/home'
    | '/dashboard/identity'
    | '/dashboard/impersonate'
    | '/dashboard/payout'
    | '/dashboard/rankings'
    | '/dashboard/resources'
    | '/dashboard/trading-accounts'
    | '/dashboard/user-certificates'
    | '/oauth2/callback/$handler'
    | '/order/$order-id'
    | '/auth/change-password'
    | '/auth/forgotten-password'
    | '/auth/invalid-link'
    | '/auth/login'
    | '/auth/registration'
    | '/dashboard/$'
    | '/auth/$'
    | '/dashboard/accounts/$account-id'
    | '/dashboard/accounts/terminal'
    | '/dashboard/request-payout/method'
    | '/order/pay/$order-id'
    | '/dashboard/request-payout'
    | '/dashboard/settings/billing-history'
    | '/dashboard/settings/edit-password'
    | '/dashboard/settings/edit-profile'
    | '/dashboard/accounts/detail/$account-id'
    | '/dashboard/accounts/restart/$account-id'
    | '/dashboard/accounts/terminal/$account-id'
  id:
    | '__root__'
    | '/'
    | '/_layout'
    | '/(authenticated)/dashboard'
    | '/(authenticated)/dashboard/_layout'
    | '/(global)/order'
    | '/(global)/order/_layout'
    | '/(global)/trial'
    | '/(global)/trial/_layout'
    | '/(unathenticated)/auth'
    | '/(unathenticated)/auth/_layout'
    | '/(authenticated)/order-status/'
    | '/(authenticated)/dashboard/_layout/_layout-settings'
    | '/(authenticated)/dashboard/_layout/affiliate'
    | '/(authenticated)/dashboard/_layout/calendar'
    | '/(authenticated)/dashboard/_layout/home'
    | '/(authenticated)/dashboard/_layout/identity'
    | '/(authenticated)/dashboard/_layout/impersonate'
    | '/(authenticated)/dashboard/_layout/payout'
    | '/(authenticated)/dashboard/_layout/rankings'
    | '/(authenticated)/dashboard/_layout/resources'
    | '/(authenticated)/dashboard/_layout/trading-accounts'
    | '/(authenticated)/dashboard/_layout/user-certificates'
    | '/(global)/oauth2/callback/$handler'
    | '/(global)/order/_layout/$order-id'
    | '/(unathenticated)/auth/_layout/change-password'
    | '/(unathenticated)/auth/_layout/forgotten-password'
    | '/(unathenticated)/auth/_layout/invalid-link'
    | '/(unathenticated)/auth/_layout/login'
    | '/(unathenticated)/auth/_layout/registration'
    | '/(authenticated)/dashboard/$/'
    | '/(global)/order/_layout/'
    | '/(global)/trial/_layout/'
    | '/(unathenticated)/auth/$/'
    | '/(authenticated)/dashboard/_layout/accounts/$account-id'
    | '/(authenticated)/dashboard/_layout/accounts/terminal'
    | '/(authenticated)/dashboard/_layout/request-payout/method'
    | '/(global)/order/_layout/pay/$order-id'
    | '/(authenticated)/dashboard/_layout/request-payout/'
    | '/(authenticated)/dashboard/_layout/_layout-settings/settings/billing-history'
    | '/(authenticated)/dashboard/_layout/_layout-settings/settings/edit-password'
    | '/(authenticated)/dashboard/_layout/_layout-settings/settings/edit-profile'
    | '/(authenticated)/dashboard/_layout/accounts/detail/$account-id'
    | '/(authenticated)/dashboard/_layout/accounts/restart/$account-id'
    | '/(authenticated)/dashboard/_layout/accounts/terminal/$account-id'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  LayoutRoute: typeof LayoutRoute
  authenticatedDashboardRoute: typeof authenticatedDashboardRouteWithChildren
  globalOrderRoute: typeof globalOrderRouteWithChildren
  globalTrialRoute: typeof globalTrialRouteWithChildren
  unathenticatedAuthRoute: typeof unathenticatedAuthRouteWithChildren
  authenticatedOrderStatusIndexRoute: typeof authenticatedOrderStatusIndexRoute
  globalOauth2CallbackHandlerRoute: typeof globalOauth2CallbackHandlerRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  LayoutRoute: LayoutRoute,
  authenticatedDashboardRoute: authenticatedDashboardRouteWithChildren,
  globalOrderRoute: globalOrderRouteWithChildren,
  globalTrialRoute: globalTrialRouteWithChildren,
  unathenticatedAuthRoute: unathenticatedAuthRouteWithChildren,
  authenticatedOrderStatusIndexRoute: authenticatedOrderStatusIndexRoute,
  globalOauth2CallbackHandlerRoute: globalOauth2CallbackHandlerRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_layout",
        "/(authenticated)/dashboard",
        "/(global)/order",
        "/(global)/trial",
        "/(unathenticated)/auth",
        "/(authenticated)/order-status/",
        "/(global)/oauth2/callback/$handler"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_layout": {
      "filePath": "_layout.tsx"
    },
    "/(authenticated)/dashboard": {
      "filePath": "(authenticated)/dashboard",
      "children": [
        "/(authenticated)/dashboard/_layout",
        "/(authenticated)/dashboard/$/"
      ]
    },
    "/(authenticated)/dashboard/_layout": {
      "filePath": "(authenticated)/dashboard/_layout.tsx",
      "parent": "/(authenticated)/dashboard",
      "children": [
        "/(authenticated)/dashboard/_layout/_layout-settings",
        "/(authenticated)/dashboard/_layout/affiliate",
        "/(authenticated)/dashboard/_layout/calendar",
        "/(authenticated)/dashboard/_layout/home",
        "/(authenticated)/dashboard/_layout/identity",
        "/(authenticated)/dashboard/_layout/impersonate",
        "/(authenticated)/dashboard/_layout/payout",
        "/(authenticated)/dashboard/_layout/rankings",
        "/(authenticated)/dashboard/_layout/resources",
        "/(authenticated)/dashboard/_layout/trading-accounts",
        "/(authenticated)/dashboard/_layout/user-certificates",
        "/(authenticated)/dashboard/_layout/accounts/$account-id",
        "/(authenticated)/dashboard/_layout/accounts/terminal",
        "/(authenticated)/dashboard/_layout/request-payout/method",
        "/(authenticated)/dashboard/_layout/request-payout/",
        "/(authenticated)/dashboard/_layout/accounts/detail/$account-id",
        "/(authenticated)/dashboard/_layout/accounts/restart/$account-id"
      ]
    },
    "/(global)/order": {
      "filePath": "(global)/order",
      "children": [
        "/(global)/order/_layout"
      ]
    },
    "/(global)/order/_layout": {
      "filePath": "(global)/order/_layout.tsx",
      "parent": "/(global)/order",
      "children": [
        "/(global)/order/_layout/$order-id",
        "/(global)/order/_layout/",
        "/(global)/order/_layout/pay/$order-id"
      ]
    },
    "/(global)/trial": {
      "filePath": "(global)/trial",
      "children": [
        "/(global)/trial/_layout"
      ]
    },
    "/(global)/trial/_layout": {
      "filePath": "(global)/trial/_layout.tsx",
      "parent": "/(global)/trial",
      "children": [
        "/(global)/trial/_layout/"
      ]
    },
    "/(unathenticated)/auth": {
      "filePath": "(unathenticated)/auth",
      "children": [
        "/(unathenticated)/auth/_layout",
        "/(unathenticated)/auth/$/"
      ]
    },
    "/(unathenticated)/auth/_layout": {
      "filePath": "(unathenticated)/auth/_layout.tsx",
      "parent": "/(unathenticated)/auth",
      "children": [
        "/(unathenticated)/auth/_layout/change-password",
        "/(unathenticated)/auth/_layout/forgotten-password",
        "/(unathenticated)/auth/_layout/invalid-link",
        "/(unathenticated)/auth/_layout/login",
        "/(unathenticated)/auth/_layout/registration"
      ]
    },
    "/(authenticated)/order-status/": {
      "filePath": "(authenticated)/order-status/index.tsx"
    },
    "/(authenticated)/dashboard/_layout/_layout-settings": {
      "filePath": "(authenticated)/dashboard/_layout/_layout-settings.tsx",
      "parent": "/(authenticated)/dashboard/_layout",
      "children": [
        "/(authenticated)/dashboard/_layout/_layout-settings/settings/billing-history",
        "/(authenticated)/dashboard/_layout/_layout-settings/settings/edit-password",
        "/(authenticated)/dashboard/_layout/_layout-settings/settings/edit-profile"
      ]
    },
    "/(authenticated)/dashboard/_layout/affiliate": {
      "filePath": "(authenticated)/dashboard/_layout/affiliate.tsx",
      "parent": "/(authenticated)/dashboard/_layout"
    },
    "/(authenticated)/dashboard/_layout/calendar": {
      "filePath": "(authenticated)/dashboard/_layout/calendar.tsx",
      "parent": "/(authenticated)/dashboard/_layout"
    },
    "/(authenticated)/dashboard/_layout/home": {
      "filePath": "(authenticated)/dashboard/_layout/home.tsx",
      "parent": "/(authenticated)/dashboard/_layout"
    },
    "/(authenticated)/dashboard/_layout/identity": {
      "filePath": "(authenticated)/dashboard/_layout/identity.tsx",
      "parent": "/(authenticated)/dashboard/_layout"
    },
    "/(authenticated)/dashboard/_layout/impersonate": {
      "filePath": "(authenticated)/dashboard/_layout/impersonate.tsx",
      "parent": "/(authenticated)/dashboard/_layout"
    },
    "/(authenticated)/dashboard/_layout/payout": {
      "filePath": "(authenticated)/dashboard/_layout/payout.tsx",
      "parent": "/(authenticated)/dashboard/_layout"
    },
    "/(authenticated)/dashboard/_layout/rankings": {
      "filePath": "(authenticated)/dashboard/_layout/rankings.tsx",
      "parent": "/(authenticated)/dashboard/_layout"
    },
    "/(authenticated)/dashboard/_layout/resources": {
      "filePath": "(authenticated)/dashboard/_layout/resources.tsx",
      "parent": "/(authenticated)/dashboard/_layout"
    },
    "/(authenticated)/dashboard/_layout/trading-accounts": {
      "filePath": "(authenticated)/dashboard/_layout/trading-accounts.tsx",
      "parent": "/(authenticated)/dashboard/_layout"
    },
    "/(authenticated)/dashboard/_layout/user-certificates": {
      "filePath": "(authenticated)/dashboard/_layout/user-certificates.tsx",
      "parent": "/(authenticated)/dashboard/_layout"
    },
    "/(global)/oauth2/callback/$handler": {
      "filePath": "(global)/oauth2/callback.$handler.tsx"
    },
    "/(global)/order/_layout/$order-id": {
      "filePath": "(global)/order/_layout/$order-id.tsx",
      "parent": "/(global)/order/_layout"
    },
    "/(unathenticated)/auth/_layout/change-password": {
      "filePath": "(unathenticated)/auth/_layout/change-password.tsx",
      "parent": "/(unathenticated)/auth/_layout"
    },
    "/(unathenticated)/auth/_layout/forgotten-password": {
      "filePath": "(unathenticated)/auth/_layout/forgotten-password.tsx",
      "parent": "/(unathenticated)/auth/_layout"
    },
    "/(unathenticated)/auth/_layout/invalid-link": {
      "filePath": "(unathenticated)/auth/_layout/invalid-link.tsx",
      "parent": "/(unathenticated)/auth/_layout"
    },
    "/(unathenticated)/auth/_layout/login": {
      "filePath": "(unathenticated)/auth/_layout/login.tsx",
      "parent": "/(unathenticated)/auth/_layout"
    },
    "/(unathenticated)/auth/_layout/registration": {
      "filePath": "(unathenticated)/auth/_layout/registration.tsx",
      "parent": "/(unathenticated)/auth/_layout"
    },
    "/(authenticated)/dashboard/$/": {
      "filePath": "(authenticated)/dashboard/$/index.tsx",
      "parent": "/(authenticated)/dashboard"
    },
    "/(global)/order/_layout/": {
      "filePath": "(global)/order/_layout/index.tsx",
      "parent": "/(global)/order/_layout"
    },
    "/(global)/trial/_layout/": {
      "filePath": "(global)/trial/_layout/index.tsx",
      "parent": "/(global)/trial/_layout"
    },
    "/(unathenticated)/auth/$/": {
      "filePath": "(unathenticated)/auth/$/index.tsx",
      "parent": "/(unathenticated)/auth"
    },
    "/(authenticated)/dashboard/_layout/accounts/$account-id": {
      "filePath": "(authenticated)/dashboard/_layout/accounts.$account-id.tsx",
      "parent": "/(authenticated)/dashboard/_layout"
    },
    "/(authenticated)/dashboard/_layout/accounts/terminal": {
      "filePath": "(authenticated)/dashboard/_layout/accounts.terminal.tsx",
      "parent": "/(authenticated)/dashboard/_layout",
      "children": [
        "/(authenticated)/dashboard/_layout/accounts/terminal/$account-id"
      ]
    },
    "/(authenticated)/dashboard/_layout/request-payout/method": {
      "filePath": "(authenticated)/dashboard/_layout/request-payout/method.tsx",
      "parent": "/(authenticated)/dashboard/_layout"
    },
    "/(global)/order/_layout/pay/$order-id": {
      "filePath": "(global)/order/_layout/pay.$order-id.tsx",
      "parent": "/(global)/order/_layout"
    },
    "/(authenticated)/dashboard/_layout/request-payout/": {
      "filePath": "(authenticated)/dashboard/_layout/request-payout/index.tsx",
      "parent": "/(authenticated)/dashboard/_layout"
    },
    "/(authenticated)/dashboard/_layout/_layout-settings/settings/billing-history": {
      "filePath": "(authenticated)/dashboard/_layout/_layout-settings/settings/billing-history.tsx",
      "parent": "/(authenticated)/dashboard/_layout/_layout-settings"
    },
    "/(authenticated)/dashboard/_layout/_layout-settings/settings/edit-password": {
      "filePath": "(authenticated)/dashboard/_layout/_layout-settings/settings/edit-password.tsx",
      "parent": "/(authenticated)/dashboard/_layout/_layout-settings"
    },
    "/(authenticated)/dashboard/_layout/_layout-settings/settings/edit-profile": {
      "filePath": "(authenticated)/dashboard/_layout/_layout-settings/settings/edit-profile.tsx",
      "parent": "/(authenticated)/dashboard/_layout/_layout-settings"
    },
    "/(authenticated)/dashboard/_layout/accounts/detail/$account-id": {
      "filePath": "(authenticated)/dashboard/_layout/accounts.detail.$account-id.tsx",
      "parent": "/(authenticated)/dashboard/_layout"
    },
    "/(authenticated)/dashboard/_layout/accounts/restart/$account-id": {
      "filePath": "(authenticated)/dashboard/_layout/accounts.restart.$account-id.tsx",
      "parent": "/(authenticated)/dashboard/_layout"
    },
    "/(authenticated)/dashboard/_layout/accounts/terminal/$account-id": {
      "filePath": "(authenticated)/dashboard/_layout/accounts.terminal.$account-id.tsx",
      "parent": "/(authenticated)/dashboard/_layout/accounts/terminal"
    }
  }
}
ROUTE_MANIFEST_END */
