import { type Order } from '@/monevis-platform/components/order/components/order-form-wrapper';
import { useFragment } from '@apollo/client';
import { type DeepPartial } from '@apollo/client/utilities';
import {
  AccountType,
  CartFragmentDoc,
  usePlaceOrderMutation,
  useSetDiscountOnCartMutation,
  useSetPlanOnCartMutation,
  useToggleAddonOnCartMutation,
  type AddonType,
  type CartFragment,
  type OrderAffiliateData,
  type PaymentMethod,
  type RegistrationInputForOrder,
} from '@graphql/index';
import { useNavigate } from '@tanstack/react-router';
import { enqueueSnackbar } from 'notistack';
import { createContext, useContext, useState, type ReactNode } from 'react';
import { useCurrentUser } from '../monevis-platform/context/current-user-context';
import { EventName, useAnalytics } from './analytics-context';
import { CredentialsContext } from './credentials-context';

interface OrderContextProps {
  loading: boolean;
  cart?: DeepPartial<CartFragment> | null | undefined;
  setInitialBalance: (initialBalance: number) => Promise<void>;
  toggleAddon: (addon: AddonType) => Promise<void>;
  applyDiscount: (discount: string) => Promise<void>;
  placeOrder: (
    method: PaymentMethod,
    orderData: Order,
    orderAffiliateData: OrderAffiliateData,
  ) => Promise<unknown>;
}

export function useOrderContext(): OrderContextProps {
  return useContext(OrderContext);
}

export const OrderContext = createContext<OrderContextProps>({
  loading: false,
  cart: null,
  setInitialBalance: () => Promise.resolve(),
  toggleAddon: () => Promise.resolve(),
  applyDiscount: () => Promise.resolve(),
  placeOrder: () => Promise.resolve(),
});

export function OrderContextProvider({
  children,
  cartId,
  affiliateCode,
}: {
  children: ReactNode;
  cartId: string;
  affiliateCode?: string | null;
}): JSX.Element {
  const [setPlanOnCartMutation] = useSetPlanOnCartMutation();
  const [toggleAddonMutation] = useToggleAddonOnCartMutation();
  const [setDiscountOnCart] = useSetDiscountOnCartMutation();
  const [placeOrderMutation] = usePlaceOrderMutation();
  const [loading, setLoading] = useState(false);
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { setToken } = useContext(CredentialsContext);
  const { identify, track } = useAnalytics();

  const { data: cart } = useFragment<CartFragment>({
    fragment: CartFragmentDoc,
    fragmentName: 'Cart',
    from: {
      __typename: 'Cart',
      id: cartId,
    },
  });
  if (!cart) {
    return <></>;
  }

  async function setInitialBalance(initialBalance: number): Promise<void> {
    if (!cart?.id) {
      return;
    }

    try {
      setLoading(true);
      const result = await setPlanOnCartMutation({
        fetchPolicy: 'network-only',
        variables: {
          id: cart.id ?? null,
          accountType: AccountType.Challenge,
          initialBalance,
        },
      });

      if (result.data?.setPlanOnCart.id) {
        track(EventName.InitiateCheckout, {});
        // setCookie('cartId', result.data.setPlanOnCart.id);
      }
    } finally {
      setLoading(false);
    }
  }

  async function toggleAddon(addon: AddonType): Promise<void> {
    if (!cart?.id) {
      return;
    }

    try {
      setLoading(true);
      await toggleAddonMutation({
        fetchPolicy: 'network-only',
        variables: {
          id: cart.id ?? null,
          addonType: addon,
        },
      });
    } finally {
      setLoading(false);
    }
  }

  async function applyDiscount(code: string): Promise<void> {
    if (!cart?.id) {
      return;
    }
    setLoading(true);
    try {
      const { data } = await setDiscountOnCart({
        variables: {
          id: cart.id,
          code,
        },
      });

      if (data?.setDiscountOnCart.appliedDiscount) {
        enqueueSnackbar({
          message: `Discount ${code} applied`,
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar({
        // @ts-expect-error - error exists
        message: String(e?.message),
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  async function placeOrder(
    paymentMethod: PaymentMethod,
    orderData: Order,
    orderAffiliateData: OrderAffiliateData,
  ): Promise<unknown> {
    const {
      password,
      refundPolicy: _refundPolicy,
      termsPolicy: _termsPolicy,
      ...data
    } = orderData;

    const _registrationData: RegistrationInputForOrder | null = !user?.me
      ? {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          countryCode: data.countryCode,
          password: password ?? '',
          invitationCode: null,
          affiliateId: affiliateCode,
        }
      : null;

    const orderAddress = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      city: data.city,
      street: data.street,
      zip: data.zip,
      countryCode: data.countryCode,
    };

    const _orderAffiliateData: OrderAffiliateData = {};

    if (orderAffiliateData?.affiliateCode) {
      _orderAffiliateData.affiliateCode = orderAffiliateData.affiliateCode;
    }

    if (orderAffiliateData?.refCode) {
      _orderAffiliateData.refCode = orderAffiliateData.refCode;
    }

    try {
      setLoading(true);
      const response = await placeOrderMutation({
        variables: {
          cartId,
          paymentMethod,
          address: orderAddress,
          registrationData: _registrationData,
          orderAffiliateData: _orderAffiliateData,
        },
      });

      if (!user?.me) {
        setToken(String(response.data?.placeOrder.token));
        if (response.data?.placeOrder?.user?.id) {
          identify(response.data?.placeOrder?.user?.id, {
            first_name: response.data?.placeOrder.user?.firstName,
            last_name: response.data?.placeOrder.user?.lastName,
            email: response.data?.placeOrder.user?.email,
          });
        }
      }

      track(EventName.Order, {
        value: Number(response.data?.placeOrder.total),
        id: String(response.data?.placeOrder.orderNumber),
        currency: response.data?.placeOrder.currency ?? 'USD',
      });
      navigate({
        to: `/order/pay/$order-id`,
        params: { 'order-id': String(response.data?.placeOrder.orderNumber) },
      });
    } catch (e) {
      if (e instanceof Error) {
        if (e.message !== 'Unauthorized') {
          enqueueSnackbar({
            message: e.message,
            variant: 'error',
          });
        }
        return e;
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <OrderContext.Provider
      value={{
        cart,
        setInitialBalance,
        toggleAddon,
        applyDiscount,
        loading,
        placeOrder,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
}
