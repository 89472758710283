import { cn } from '@/utils';
import * as React from 'react';

export interface TextFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
  error?: string;
  description?: string;
  labelClassName?: string;
  'error-data-testid'?: string;
  'data-testid'?: string;
}

export function FormLabel({
  error,
  id,
  labelClassName,
  label,
}: TextFieldProps): JSX.Element {
  return (
    <label
      htmlFor={id}
      className={cn(
        'mb-1 text-sm font-normal text-default-gray-400',
        {
          'text-danger': error,
        },
        labelClassName,
      )}
    >
      {label}
    </label>
  );
}

export function TextField({
  className,
  error,
  description,
  children,
  label,
  labelClassName,
  'error-data-testid': errorDataTestId = 'errorDataTestId',
  'data-testid': dataTestId = 'inputTestId',
}: TextFieldProps): JSX.Element {
  const id = React.useId();

  const childrenItems = React.Children.map(children, (child) => {
    return React.cloneElement(child as React.ReactElement, {
      id,
    });
  });

  return (
    <div data-testid={dataTestId} className={cn(className)}>
      {label && (
        <div className="flex items-center gap-1">
          <FormLabel
            error={error}
            id={id}
            labelClassName={labelClassName}
            label={label}
          />
        </div>
      )}
      {childrenItems}
      {description && (
        <p className="mt-1 text-[0.8rem] text-black opacity-50">
          {description}
        </p>
      )}
      {error && (
        <p
          data-testid={errorDataTestId}
          className="mt-1 text-[0.8rem] text-danger"
        >
          {error}
        </p>
      )}
    </div>
  );
}
TextField.displayName = 'TextField';
