import { PageHeading } from '@/components/base/page-heading';
import Section from '@/components/base/section';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/base/select';
import { CryptoCurrency, RiseNoLabel } from '@/components/icons';
import { WithdrawalPaymentMethod } from '@graphql/index';
import { useTranslation } from 'react-i18next';

export function SelectPayoutMethod({
  onChange,
  handleNext,
}: {
  onChange: (value: WithdrawalPaymentMethod) => void;
  handleNext?: () => void;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="flex w-full justify-center">
      <Section
        variant="secondary"
        className="w-fit min-w-[350px] md:min-w-[630px]"
      >
        <div className="flex w-full flex-col gap-6">
          <PageHeading subheading={t('requestPayout.readyForPayoutSubheading')}>
            {t('requestPayout.readyForPayout')}
          </PageHeading>
          <Select
            onValueChange={(currentValue) => {
              onChange(currentValue as WithdrawalPaymentMethod);
              handleNext && handleNext();
            }}
          >
            <SelectTrigger
              data-testid="select-account-trigger"
              size="sm"
              variant="secondary"
            >
              <SelectValue placeholder={t('requestPayout.placeholder')} />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup
                data-testid="select-account-content"
                className="divide-y divide-default-gray-800"
              >
                <SelectItem
                  data-testid="rise"
                  value={WithdrawalPaymentMethod.Rise}
                >
                  <div className="flex w-full items-center gap-2">
                    <RiseNoLabel />
                    <span>
                      {
                        //eslint-disable-next-line i18next/no-literal-string -- .
                      }
                      Rise
                    </span>
                  </div>
                </SelectItem>
                <SelectItem
                  data-testid="crypto"
                  value={WithdrawalPaymentMethod.DirectCrypto}
                >
                  <div className="flex w-full items-center gap-2">
                    <CryptoCurrency />
                    <span>
                      {
                        //eslint-disable-next-line i18next/no-literal-string -- .
                      }
                      Crypto
                    </span>
                  </div>
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </Section>
    </div>
  );
}
