import { Button } from '@/components/base/button';
import Section from '@/components/base/section';
import { AccountDetails } from '@/monevis-platform/components/(authenticated)/account-detail/account-details';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { Confetti } from './components/confetti';

export function AccountProvisioned({
  accountId,
}: {
  accountId: string;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <Section variant="secondary" className="mx-auto w-full max-w-4xl">
        <div className="flex w-full flex-col">
          <AccountDetails accountId={accountId} />
          <Link
            className="w-full p-6"
            to="/dashboard/accounts/$account-id"
            params={{ 'account-id': accountId }}
          >
            <Button className="w-full">
              {t('accountProvisioned.detailsButton')}
            </Button>
          </Link>
        </div>
      </Section>
      <Confetti />
    </>
  );
}
