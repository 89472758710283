import { Faq } from '@/components/base/faq';
import { Logo, LogoVariant } from '@/components/base/logo';
import { ThemeSwitcher } from '@/components/base/mode-switcher';
import { VerticalDivider } from '@/components/base/vertical-divider';
import { usePageLayout } from '@/context/layout-context';
import { SidebarWithItems } from '@/monevis-platform/components/(authenticated)/layout/sidebar';
import { useCurrentUser } from '@/monevis-platform/context/current-user-context';
import { cn } from '@/utils';
import { faArrowLeft, faBarsSort } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { Link } from '@tanstack/react-router';
import { useState } from 'react';
import { ProfileDropdown } from './profile-dropdown';

export default function Header(): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { title } = usePageLayout();
  const data = useCurrentUser();
  // const router = useRouter();
  // const navigate = useNavigate();

  return (
    <div className="relative flex w-full items-center justify-between bg-white px-8 py-5 text-default-gray-900 dark:bg-default-gray-950 dark:text-white sm:rounded-2xl">
      {/* HEADER START */}
      <div className="flex h-full items-center justify-center">
        {data?.me && (
          <button
            type="button"
            onClick={() => {
              setIsMenuOpen(true);
            }}
            className="flex lg:hidden"
          >
            <FontAwesomeIcon
              className="mr-5 h-6 w-6 cursor-pointer"
              icon={faBarsSort}
            />
          </button>
        )}
        {!data?.me && (
          <a
            className="flex items-center text-xl"
            type="button"
            href="https://monevis.com"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </a>
        )}

        {data?.me && (
          <div className="hidden items-center lg:flex">
            <h1 className="mr-5 text-2xl font-medium">{title}</h1>
            {/* <VerticalDivider size="lg" /> */}
          </div>
        )}
      </div>

      <div className={cn('flex', { 'lg:hidden': data })}>
        <Link className="flex h-7 w-full justify-center" to="/auth/login">
          <Logo variant={LogoVariant.Monevis} />
        </Link>
      </div>

      {/* ICONS */}
      {data?.me && (
        <div className="flex items-center justify-center gap-5">
          <div className="hidden h-full items-center gap-5 lg:flex">
            <div className="flex items-center justify-center gap-5">
              <VerticalDivider />
              <Faq />
              <ThemeSwitcher />
              <VerticalDivider />
            </div>
          </div>
          <ProfileDropdown />
        </div>
      )}

      {!data?.me && <div />}

      {/* SIDEBAR */}
      <Transition show={isMenuOpen}>
        <Dialog
          className="fixed inset-0 z-50 h-full lg:hidden"
          onClose={() => {
            setIsMenuOpen(false);
          }}
        >
          <TransitionChild
            enter="transition duration-100 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <button
              type="button"
              onClick={() => {
                setIsMenuOpen(false);
              }}
              className="fixed inset-0 cursor-default bg-black/30"
            />
          </TransitionChild>

          <TransitionChild
            enter="transition duration-200 ease-out"
            enterFrom="transform -translate-x-full"
            enterTo="transform translate-x-0"
            leave="transition duration-200 ease-in-out"
            leaveFrom="transform translate-x-0"
            leaveTo="transform -translate-x-full"
          >
            <div className="h-full w-fit overflow-y-auto">
              <div className="z-50 h-full">
                <SidebarWithItems
                  closeMenu={() => {
                    setIsMenuOpen(false);
                  }}
                />
              </div>
            </div>
          </TransitionChild>
        </Dialog>
      </Transition>
    </div>
  );
}
