import { Controller, useFormContext } from 'react-hook-form';
import PhoneNumberInput from '../../../monevis-platform/components/base/form/phone-input';
import { TextField, type TextFieldProps } from './text-field';

export interface FormPhoneNumberInputProps
  extends Omit<TextFieldProps, 'onError'> {
  name: string;
  defaultValue?: string;
  onError: (error: string | null) => void;
  'data-testid'?: string;
}
export function FormPhoneNumberInput({
  name,
  onError,
  'data-testid': dataTestId = 'phoneInput',
  ...props
}: FormPhoneNumberInputProps): JSX.Element {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          data-testid={dataTestId}
          {...props}
          error={
            errors[name]?.message ? String(errors[name]?.message) : undefined
          }
        >
          <PhoneNumberInput
            onError={(error) => {
              onError(error);
            }}
            value={value}
            variant="secondary"
            onChange={onChange}
          />
        </TextField>
      )}
      name={name}
    />
  );
}
FormPhoneNumberInput.displayName = 'FormPhoneNumberInput';
