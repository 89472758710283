import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import { usePageLayout } from '@/context/layout-context';
import { AccountProvisioned } from '@/monevis-platform/components/order/account-provisioned';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/accounts/detail/$account-id',
)({
  component: () => (
    <PageWrapper title="Account Detail">
      <AccountDetailPage />
    </PageWrapper>
  ),
});

function AccountDetailPage(): JSX.Element {
  usePageLayout({ title: 'Account Detail' });
  const { 'account-id': accountId } = Route.useParams();
  return (
    <ScreenContainer>
      <AccountProvisioned accountId={accountId} />
    </ScreenContainer>
  );
}
