import { Button } from '@/components/base/button';
import { Combobox } from '@/components/base/combobox';
import { PageHeading } from '@/components/base/page-heading';
import { Skeleton } from '@/components/base/skeleton';
import { TradingAccountCardWithData } from '@/monevis-platform/components/trading-account-card';
import { faFire } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AccountType,
  ChallengeStatus,
  useGetBrokerAccountsQuery,
  type BrokerAccountFragment,
} from '@graphql/index';
import { Link } from '@tanstack/react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import NewChallenge from '../dashboard/new-challenge';

const typeSortValues = [
  {
    value: AccountType.Funded,
    label: 'Funded',
  },
  {
    value: AccountType.Challenge,
    label: 'Challenge',
  },
  {
    value: AccountType.Trial,
    label: 'Trial',
  },
  {
    value: '',
    label: 'All',
  },
];

function createAccountsMap(
  data: BrokerAccountFragment[],
): Record<string, BrokerAccountFragment[]> {
  const accountsMap: Record<string, BrokerAccountFragment[]> = {
    Funded: [],
    Challenge: [],
    Trial: [],
  };

  data.forEach((account) => {
    if (account.accountType === AccountType.Funded) {
      accountsMap.Funded.push(account);
    } else if (account.accountType === AccountType.Challenge) {
      accountsMap.Challenge.push(account);
    } else if (account.accountType === AccountType.Trial) {
      accountsMap.Trial.push(account);
    }
  });

  return accountsMap;
}

export function TradingAccountsList({
  data,
}: {
  data: BrokerAccountFragment[];
}): JSX.Element {
  const { t } = useTranslation();
  const groupedData = createAccountsMap(data);
  return (
    <div className="flex flex-col gap-6">
      {Object.keys(groupedData).map((item) => {
        if (groupedData[item].length > 0) {
          return (
            <div key={item}>
              <PageHeading>
                {item} {t('newChallenge.accounts')}
              </PageHeading>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                {groupedData[item].map((account) => {
                  return (
                    <TradingAccountCardWithData
                      key={account.id}
                      login={account.login}
                    />
                  );
                })}
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

export function TradingAccountsListWithData(): JSX.Element {
  const { data, loading } = useGetBrokerAccountsQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <div className="flex w-full flex-col gap-5">
        <div className="flex gap-3">
          <Skeleton className="h-full min-h-10 w-full max-w-24 rounded-xl" />
          <Skeleton className="h-full min-h-10 w-full max-w-24 rounded-xl" />
        </div>
        <div className="grid h-full w-full grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
          <Skeleton className="h-full min-h-32 w-full min-w-80 rounded-xl" />
          <Skeleton className="h-full min-h-32 w-full min-w-80 rounded-xl" />
        </div>
      </div>
    );
  }

  invariant(data?.me.brokerAccounts, `Broker account missing`);

  return (
    <>
      {Number(data?.me.brokerAccounts.length) === 0 && <NewChallenge />}
      {Number(data?.me.brokerAccounts.length) > 0 && (
        <TradingAccountsListWithSortAndFilter data={data.me.brokerAccounts} />
      )}
    </>
  );
}

const statusSortOrder = {
  [ChallengeStatus.Evaluation]: 1,
  [ChallengeStatus.Passed]: 2,
  [ChallengeStatus.Failed]: 3,
};

export function TradingAccountsListWithSortAndFilter({
  data,
}: {
  data: BrokerAccountFragment[];
}): JSX.Element {
  const [typeSortValue, setTypeSortValue] = useState('');

  const statusSortedAccounts = [...data].sort((a, b) => {
    if (
      a.accountType === AccountType.Funded &&
      a.challengeStatus === ChallengeStatus.Failed
    ) {
      return 1;
    }
    if (
      b.accountType === AccountType.Funded &&
      b.challengeStatus === ChallengeStatus.Failed
    ) {
      return -1;
    }
    if (
      a.accountType === AccountType.Funded &&
      b.accountType !== AccountType.Funded
    ) {
      return -1;
    }
    if (
      a.accountType !== AccountType.Funded &&
      b.accountType === AccountType.Funded
    ) {
      return 1;
    }
    return (
      statusSortOrder[a.challengeStatus] - statusSortOrder[b.challengeStatus]
    );
  });

  const filteredAccounts = typeSortValue
    ? statusSortedAccounts.filter((account) => {
        return (
          account.accountType.toLocaleLowerCase() ===
          typeSortValue.toLocaleLowerCase()
        );
      })
    : statusSortedAccounts;
  const { t } = useTranslation();
  return (
    <div className="flex h-full flex-col justify-between gap-3">
      <div className="flex flex-col gap-3">
        <div className="flex w-full flex-col items-center justify-between gap-5 sm:flex-row">
          <Link to="/order" className="block w-full sm:hidden">
            <Button>
              <FontAwesomeIcon icon={faFire} className="h-[20px]" />
              <span className="block leading-3">
                {t('newChallenge.startChallenge')}
              </span>
            </Button>
          </Link>
          <div className="flex w-full items-center gap-4 sm:w-max">
            <Combobox
              items={typeSortValues}
              value={typeSortValue}
              onChange={(value: string) => {
                value === typeSortValue
                  ? setTypeSortValue('')
                  : setTypeSortValue(value);
              }}
              placeholder={t('dropDown.allType')}
            />
          </div>
          <Link to="/order" className="hidden sm:block">
            <Button>
              <FontAwesomeIcon icon={faFire} className="h-[20px]" />
              <span className="block leading-3">
                {t('newChallenge.startChallenge')}
              </span>
            </Button>
          </Link>
        </div>

        <TradingAccountsList data={filteredAccounts} />
      </div>
    </div>
  );
}
