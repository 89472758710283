import { cn } from '@/utils';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { JoinSocialsSection } from '../monevis-platform/components/(authenticated)/calendar/help-section';
import { Button } from './base/button';
import { PageHeading } from './base/page-heading';

export function NotFoundPage({
  className,
}: {
  className?: string;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        'flex h-full min-h-screen w-full flex-col justify-between gap-6 overflow-scroll bg-default-gray-50 text-default-gray-950 dark:bg-dark dark:text-white',
        className,
      )}
    >
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="order-last flex h-full flex-col justify-center text-center md:order-1 md:text-start">
          <PageHeading size="xxlarge" subheading={t('notFoundPage.subheading')}>
            {t('notFoundPage.title')}
          </PageHeading>
          <Link to="/">
            <Button className="mt-4 w-full md:w-fit">
              {t('notFoundPage.goBackButton')}
            </Button>
          </Link>
        </div>
        <div className="order-1 flex w-full items-center justify-center md:order-last">
          <img src="/images/not-found-mascot.png" alt="error" />
        </div>
      </div>

      <JoinSocialsSection>
        <PageHeading subheading={t('notFoundPage.joinCommunitySubheading')}>
          {t('notFoundPage.joinCommunityTitle')}
        </PageHeading>
      </JoinSocialsSection>
    </div>
  );
}
