import { PageHeading } from '@/components/base/page-heading';
import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import { usePageLayout } from '@/context/layout-context';
import { JoinSocialsSection } from '@/monevis-platform/components/(authenticated)/calendar/help-section';
import { Payouts } from '@/monevis-platform/components/(authenticated)/payout/payouts';
import { useTranslation } from 'react-i18next';

import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/payout',
)({
  component: () => (
    <PageWrapper title="Payout - Monevis">
      <PayoutPage />
    </PageWrapper>
  ),
});

function PayoutPage(): JSX.Element {
  const { t } = useTranslation();
  usePageLayout({ title: t('payoutPage.title') });

  return (
    <ScreenContainer className="justify-between">
      <Payouts />
      <JoinSocialsSection>
        <PageHeading subheading={t('payoutPage.joinCommunitySubheading')}>
          {t('payoutPage.helpHeading')}
        </PageHeading>
      </JoinSocialsSection>
    </ScreenContainer>
  );
}

export default PayoutPage;
