import { PageHeading } from '@/components/base/page-heading';
import { useTranslation } from 'react-i18next';
import { Badge } from './badge';

export function Info(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col justify-between gap-5 md:flex-row md:items-center">
      <div className="flex flex-col">
        <PageHeading
          className="mb-3"
          size="xxlarge"
          subheading={t('info.subheading')}
        >
          {t('info.heading')}
        </PageHeading>
        <div className="mt-3 flex flex-wrap gap-2">
          <Badge>{t('info.own')}</Badge>
          <Badge>{t('info.staticDrawdown')}</Badge>
          <Badge>{t('info.tightSpreads')}</Badge>
          <Badge>{t('info.newsTrading')}</Badge>
          <Badge>{t('info.weekendHolding')}</Badge>
          <Badge>{t('info.easAllowed')}</Badge>
        </div>
      </div>
      <div className="flex flex-1 flex-row items-center space-x-2 md:justify-end">
        <div>
          <img
            src="/images/mt5-logo.png"
            className="h-12 w-auto"
            alt="metatrader logo"
          />
        </div>
        {/*eslint-disable-next-line i18next/no-literal-string -- name*/}
        <div className="font-bold">MonevisBrokers-Live</div>
      </div>
    </div>
  );
}
