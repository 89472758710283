import { Button } from '@/components/base/button';
import { PageHeading } from '@/components/base/page-heading';
import Section from '@/components/base/section';
import { Badge } from '@/monevis-platform/components/order/components/badge';
import {
  useCheckBrokerAccountRestartQuery,
  useRestartAccountMutation,
} from '@graphql/index';
import { useNavigate } from '@tanstack/react-router';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { RestartCountdown } from '../account-detail/head/components/restart-countdown';

export function AccountRestartPage({
  accountId,
}: {
  accountId: string;
}): JSX.Element {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const intl = useIntl();
  const { t } = useTranslation();
  const { data, loading: dataLoading } = useCheckBrokerAccountRestartQuery({
    variables: {
      login: accountId,
    },
    fetchPolicy: 'network-only',
  });

  const [restartAccount] = useRestartAccountMutation();

  async function performRestart(): Promise<void> {
    setLoading(true);
    try {
      const { data: order } = await restartAccount({
        variables: {
          login: accountId,
        },
      });
      if (order?.restartAccount.orderNumber) {
        navigate({
          to: `/order/pay/$order-id`,
          params: { 'order-id': order.restartAccount.orderNumber },
        });
      } else {
        enqueueSnackbar({
          message: t('accountRestartPage.errorMessage'),
          variant: 'error',
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar({
          message: e.message,
          variant: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  }

  if (dataLoading || !data?.checkAccountRestart.eligible) {
    return <></>;
  }

  const totalFormatted = intl.formatNumber(
    data?.checkAccountRestart.newPrice ?? 0,
    {
      style: 'currency',
      currency: data?.checkAccountRestart.currency ?? 'USD',
    },
  );

  const subTotalFormatted = intl.formatNumber(
    data?.checkAccountRestart.originalPrice ?? 0,
    {
      style: 'currency',
      currency: data?.checkAccountRestart.currency ?? 'USD',
    },
  );

  return (
    <div>
      <Section
        variant="secondary"
        className="mx-auto flex w-full max-w-4xl flex-col"
      >
        <div className="flex w-full flex-col gap-4">
          <div>
            <PageHeading
              className="text-center"
              subheading={t('accountRestartPage.subheading')}
            >
              {t('accountRestartPage.pageTitle')} {accountId}
            </PageHeading>
          </div>
          <div className="my-6 flex w-full justify-center text-center text-3xl font-bold">
            <RestartCountdown time={data?.checkAccountRestart.expiryTime} />
          </div>
          <div className="mt-4 flex flex-col items-center">
            <PageHeading size="xxlarge">
              {t('accountRestartPage.totalToPay')}
            </PageHeading>
            <Badge className="min-w-52" size="lg">
              <div className="mr-2 text-sm text-red-400 line-through">
                {subTotalFormatted}
              </div>

              {totalFormatted}
            </Badge>
          </div>
          <div className="flex justify-center">
            <Button
              loading={loading}
              onClick={async () => {
                await performRestart();
              }}
            >
              {t('accountRestartPage.payButton')}
            </Button>
          </div>
        </div>
      </Section>
    </div>
  );
}
