import { Button } from '@/components/base/button';
import { Dialog, DialogContent, DialogTrigger } from '@/components/base/dialog';
import { Checkbox } from '@/components/base/form/checkbox';
import { FormField } from '@/components/base/form/form-field';
import { Input } from '@/components/base/form/input';
import { Label } from '@/components/base/label';
import { PageHeading } from '@/components/base/page-heading';
import { UsdcLogo } from '@/components/icons';
import { cn } from '@/utils';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export enum Crypto {
  USDC = 'USDC',
}

export const cryptoDataMap = {
  [Crypto.USDC]: {
    label: 'USDC',
    icon: <UsdcLogo height={70} width={70} viewBox="0 0 2000 2000" />,
    value: 'USDC',
    warning: 'Arbitrum',
    warningText: 'requestPayout.usdcWarning',
  },
  '': { label: '', icon: null, value: '', warning: '', warningText: '' },
};

export function CryptoDetailsForm({
  handleNext,
  handleBack,
  loading,
}: {
  handleNext?: () => void;
  handleBack?: () => void;
  loading?: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const [warningModal, setWarningModal] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxCheckedModal, setIsCheckboxCheckedModal] = useState(false);

  const methods = useFormContext();
  const {
    formState: { errors },
    watch,
  } = methods;

  const watchCrypto = watch('crypto') as Crypto;
  const watchTargetAddress = watch('targetAddress') as string;
  const watchTargetAddressConfirmation = watch(
    'targetAddressConfirm',
  ) as string;
  const watchDisplayName = watch('displayName') as string;

  const disableSubmit =
    Object.keys(errors).length > 0 ||
    !watchTargetAddress ||
    !watchTargetAddressConfirmation ||
    !watchDisplayName ||
    !isCheckboxChecked;

  function handleSubmitButton(): void {
    if (cryptoDataMap[watchCrypto]?.warning) {
      setWarningModal(true);
    } else if (handleNext) {
      handleNext();
    }
  }

  return (
    <div
      data-testid="crypto-details-form"
      className="flex w-full justify-center"
    >
      <div className="w-fit min-w-[350px] md:min-w-[630px]">
        <div className="flex w-full flex-col gap-6">
          <div className="flex items-center gap-4">
            {cryptoDataMap[watchCrypto].icon}
            <PageHeading className="mb-0">
              {cryptoDataMap[watchCrypto].label}
              {t('requestPayout.cryptoInfoTitle')}
            </PageHeading>
            {cryptoDataMap[watchCrypto]?.warning && (
              <Label variant="red">{cryptoDataMap[watchCrypto]?.warning}</Label>
            )}
          </div>

          <FormField
            labelClassName="dark:text-white text-default-gray-950"
            label={t('requestPayout.cryptoInfoAddressLabel')}
            name="targetAddress"
            defaultValue={watchTargetAddress}
          >
            <Input
              data-testid="cryptoInfoAddress"
              inputSize="sm"
              variant="secondary"
              placeholder={t('requestPayout.cryptoInfoAddressPlaceholder')}
            />
          </FormField>

          <FormField
            labelClassName="dark:text-white text-default-gray-950"
            label={t('requestPayout.cryptoInfoAddressConfirmLabel')}
            name="targetAddressConfirm"
            defaultValue={watchTargetAddressConfirmation}
          >
            <Input
              data-testid="cryptoInfoAddressConfirm"
              inputSize="sm"
              variant="secondary"
              placeholder={t('requestPayout.cryptoInfoAddressPlaceholder')}
            />
          </FormField>

          <FormField
            labelClassName="dark:text-white text-default-gray-950"
            label={t('requestPayout.displayNameLabel')}
            name="displayName"
            defaultValue={watchDisplayName}
          >
            <Input
              data-testid="crypto-display-name"
              inputSize="sm"
              variant="secondary"
              placeholder={t('requestPayout.displayNamePlaceholder')}
            />
          </FormField>

          <label
            htmlFor="warningCheck"
            className="mt-2 flex items-center gap-2 rounded-lg border border-danger bg-danger/50 px-4 py-1 text-white/80"
          >
            <Checkbox
              checked={isCheckboxChecked}
              onChange={(e) => {
                setIsCheckboxChecked(e.target.checked);
              }}
              id="warningCheck"
              data-testid="warningCheck"
            />
            <span
              className={cn(
                'block cursor-pointer text-xs text-default-gray-950 dark:text-white',
              )}
            >
              {t(cryptoDataMap[watchCrypto]?.warningText)}
            </span>
          </label>

          <div className="flex w-full justify-between gap-8">
            <Button
              onClick={() => {
                handleBack && handleBack();
              }}
              variant="secondary"
              size="sm"
              className="w-full"
            >
              {t('requestPayout.buttonBack')}
            </Button>
            <Button
              loading={loading}
              disabled={disableSubmit}
              onClick={handleSubmitButton}
              className="w-full"
              size="sm"
            >
              {t('requestPayout.buttonAdd')}
            </Button>
          </div>
        </div>
      </div>

      <Dialog open={warningModal} onOpenChange={setWarningModal}>
        <DialogTrigger />
        <DialogContent data-testId="warningModal" className="max-w-[600px]">
          <h2 className="text-lg font-bold text-red-500">
            {' '}
            {t('requestPayout.warning')}
          </h2>

          <label
            htmlFor="warningCheckModal"
            className="flex items-center gap-2"
          >
            <Checkbox
              checked={isCheckboxCheckedModal}
              onChange={(e) => {
                setIsCheckboxCheckedModal(e.target.checked);
              }}
              id="warningCheckModal"
              data-testid="warningCheckModal"
            />
            <span
              className={cn(
                'block cursor-pointer text-xs text-default-gray-950 dark:text-white',
              )}
            >
              {t(cryptoDataMap[watchCrypto]?.warningText)}
            </span>
          </label>

          <div className="mt-4 flex justify-end gap-4">
            <Button
              onClick={() => {
                setWarningModal(false);
              }}
              variant="secondary"
            >
              {t('requestPayout.closeButton')}
            </Button>
            <Button
              disabled={!isCheckboxCheckedModal}
              onClick={() => {
                setWarningModal(false);
                handleNext && handleNext();
              }}
              data-testId="continueButton"
            >
              {t('requestPayout.continueButton')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
