import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from '@/components/base/dialog';
import { PageHeading } from '@/components/base/page-heading';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useArchiveBrokerAccountMutation } from '@graphql/index';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/base/button';

export function TradingAccountArchiveModal({
  login,
}: {
  login: string;
}): JSX.Element {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [archiveAccountMutation, { loading }] =
    useArchiveBrokerAccountMutation();

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        setOpen(value);
      }}
    >
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        <FontAwesomeIcon
          data-testid="archive-button"
          icon={faTrash}
          className="transition-all hover:scale-110  hover:opacity-80"
        />
      </DialogTrigger>
      <DialogContent className="max-w-3xl gap-6 md:pb-0">
        <div className="flex flex-col items-start justify-center gap-4 pb-4 text-2xl">
          <PageHeading
            size="xxlarge"
            subheading={t('Archive.ConfirmationSubheading')}
          >
            {t('Archive.AccountHeading')}
            {login}
          </PageHeading>
          <div className="flex gap-4">
            <DialogClose
              onClick={() => {
                setOpen(false);
              }}
            >
              <Button loading={loading} variant="outlined">
                {t('Archive.Cancel')}
              </Button>
            </DialogClose>
            <Button
              loading={loading}
              onClick={async () => {
                await archiveAccountMutation({
                  variables: {
                    login,
                  },
                  refetchQueries: ['GetBrokerAccounts'],
                  onCompleted: () => {
                    enqueueSnackbar({
                      message: t('Archive.AccountArchivedMessage', { login }),
                      variant: 'success',
                    });
                  },
                });
              }}
            >
              {t('Archive.AccountButton')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
