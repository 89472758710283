import { EditPasswordWithMuation } from '@/monevis-platform/components/(authenticated)/settings-layout/edit-password';
import { MainWrapper } from '@/monevis-platform/components/(authenticated)/settings-layout/main-wrapper';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/_layout-settings/settings/edit-password',
)({ component: SettingsPage });

function SettingsPage(): JSX.Element {
  const { t } = useTranslation();
  return (
    <MainWrapper title={t('settingsPage.editPasswordTitle')}>
      <EditPasswordWithMuation />
    </MainWrapper>
  );
}

export default SettingsPage;
