import { Button } from '@/components/base/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/base/dropdown-menu';
import { faEur } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface CurrencyFilterProps {
  selectedCurrencies: string[];
  setSelectedCurrencies: React.Dispatch<React.SetStateAction<string[]>>;
  currencies: string[];
}

export function CurrencyFilter({
  selectedCurrencies,
  setSelectedCurrencies,
  currencies,
}: CurrencyFilterProps): JSX.Element {
  function handleChange(currency: string): void {
    if (selectedCurrencies.includes(currency)) {
      setSelectedCurrencies(
        selectedCurrencies.filter((item) => item !== currency),
      );
    } else {
      setSelectedCurrencies([...selectedCurrencies, currency]);
    }
  }
  function removeCurrencies(): void {
    setSelectedCurrencies([]);
  }
  function addAllCurrencies(): void {
    setSelectedCurrencies(currencies);
  }
  const { t } = useTranslation();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="focus-visible:ring-0">
        <Button
          data-testid="currency-filter-button"
          role="combobox"
          className="hover:dark:bg-default-gray-960 w-full min-w-24 gap-2 border-none px-3 py-2 dark:bg-default-gray-950"
        >
          {t('calendar.filter')} <FontAwesomeIcon icon={faEur} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>
          {' '}
          <div className="flex w-full flex-col justify-between">
            <span>{t('calendar.currency')}</span>
            <div className="flex items-end justify-end gap-3">
              <button type="button" onClick={removeCurrencies}>
                <span className="text-xs font-normal underline">
                  {t('calendar.removeAll')}
                </span>
              </button>
              <button type="button" onClick={addAllCurrencies}>
                <span className="text-xs font-normal underline">
                  {t('calendar.addAll')}
                </span>
              </button>
            </div>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {currencies.map((currency) => (
          <DropdownMenuCheckboxItem
            key={currency}
            data-testid={`item-${currency}`}
            checked={selectedCurrencies.includes(currency)}
            onClick={(e) => {
              e.preventDefault();
              handleChange(currency);
            }}
          >
            {currency}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
