import { PageHeading } from '@/components/base/page-heading';

import { Button } from '@/components/base/button';
import { FormField } from '@/components/base/form/form-field';
import { Input } from '@/components/base/form/input';
import Section from '@/components/base/section';
import { RiseNoLabel } from '@/components/icons';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function RiseDetailsForm({
  handleNext,
  handleBack,
  loading,
}: {
  handleNext?: () => void;
  handleBack?: () => void;
  loading?: boolean;
}): JSX.Element {
  const { t } = useTranslation();

  const methods = useFormContext();
  const {
    formState: { errors },
    watch,
  } = methods;
  const watchTargetAddress = watch('targetAddress') as string;
  const watchDisplayName = watch('displayName') as string;
  const disableSubmit = Object.keys(errors).length > 0 || !watchTargetAddress;

  return (
    <div data-testid="rise-details-form" className="flex w-full justify-center">
      <Section
        variant="secondary"
        className="w-fit min-w-[350px] md:min-w-[630px]"
      >
        <div className="flex w-full flex-col gap-6">
          <div className="flex items-center gap-4">
            <RiseNoLabel />
            <PageHeading className="mb-0">
              {t('requestPayout.riseMethodTitle')}
            </PageHeading>
          </div>
          <FormField
            labelClassName="dark:text-white text-default-gray-950"
            label={t('requestPayout.riseMethodLabel')}
            name="targetAddress"
          >
            <Input
              inputSize="sm"
              variant="secondary"
              placeholder={t('requestPayout.riseMethodPlaceholder')}
            />
          </FormField>
          <FormField
            labelClassName="dark:text-white text-default-gray-950"
            label={t('requestPayout.displayNameLabel')}
            name="displayName"
            defaultValue={watchDisplayName}
          >
            <Input
              inputSize="sm"
              variant="secondary"
              placeholder={t('requestPayout.displayNamePlaceholder')}
            />
          </FormField>
          <div className="flex w-full justify-between gap-8">
            <Button
              onClick={handleBack}
              variant="secondary"
              size="sm"
              className="w-full"
            >
              {t('requestPayout.buttonBack')}
            </Button>
            <Button
              disabled={disableSubmit}
              onClick={handleNext}
              className="w-full"
              size="sm"
              loading={loading}
            >
              {t('requestPayout.buttonAdd')}
            </Button>
          </div>
        </div>
      </Section>
    </div>
  );
}
