import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import Section from '@/components/base/section';
import { usePageLayout } from '@/context/layout-context';
import { Trial } from '@/monevis-platform/components/order/trial';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/(global)/trial/_layout/')({
  component: () => (
    <PageWrapper title="Trial - Monevis">
      <TrialPage />
    </PageWrapper>
  ),
});

function TrialPage(): JSX.Element {
  const { t } = useTranslation();
  usePageLayout({ title: t('trialPage.title') });

  return (
    <ScreenContainer className="justify-between">
      <Section variant="secondary">
        <Trial />
      </Section>
    </ScreenContainer>
  );
}

export default TrialPage;
