import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import { usePageLayout } from '@/context/layout-context';
import { AddPayoutMethod } from '@/monevis-platform/components/(authenticated)/request-payout/payout-method';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/request-payout/method',
)({
  component: () => (
    <PageWrapper title="Add payout method - Monevis">
      <AddPayoutMethodPage />
    </PageWrapper>
  ),
});

function AddPayoutMethodPage(): JSX.Element {
  const { t } = useTranslation();
  usePageLayout({ title: t('addPayoutMethodPage.title') });

  return (
    <ScreenContainer className="justify-between">
      <AddPayoutMethod />
    </ScreenContainer>
  );
}

export default AddPayoutMethodPage;
