import Sidebar, {
  type SidebarProps,
} from '@/components/(authenticated)/layout/sidebar';
import { Button } from '@/components/base/button';
import {
  faCalendarAlt,
  faChartLine,
  faCubes,
  faFileCertificate,
  faFileDownload,
  faHouse,
  faUsers,
  faWallet,
} from '@fortawesome/pro-regular-svg-icons';
import { faFire } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

const sidebarItems = [
  { to: '/dashboard/home', icon: faHouse, label: 'sidebar.dashboard' },
  {
    to: '/dashboard/trading-accounts',
    icon: faCubes,
    label: 'sidebar.tradingAccounts',
  },
  {
    to: '/dashboard/accounts/terminal',
    icon: faChartLine,
    label: 'sidebar.terminal',
  },
  { to: '/dashboard/payout', icon: faWallet, label: 'sidebar.payout' },
  { to: '/dashboard/calendar', icon: faCalendarAlt, label: 'sidebar.calendar' },
  {
    to: '/dashboard/user-certificates',
    icon: faFileCertificate,
    label: 'sidebar.certificate',
  },
  { to: '/dashboard/affiliate', icon: faUsers, label: 'sidebar.affiliate' },
  {
    to: '/dashboard/resources',
    icon: faFileDownload,
    label: 'sidebar.resources',
  },
];

type SidebarWithItemsProps = Omit<SidebarProps, 'items'>;

export function SidebarWithItems({
  ...props
}: SidebarWithItemsProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Sidebar items={sidebarItems} {...props}>
      <Link className="flex w-full flex-col" to="/order">
        <Button className="mx-2 my-4">
          <FontAwesomeIcon icon={faFire} className="h-[20px]" />
          <span className="block leading-3">{t('sidebar.button')}</span>
        </Button>
      </Link>
    </Sidebar>
  );
}
