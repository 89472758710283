import { Button } from '@/components/base/button';
import { useAnalytics } from '@/context/analytics-context';
import { CredentialsContext } from '@/context/credentials-context';
import { useCurrentUser } from '@/monevis-platform/context/current-user-context';
import { isBannedCountry } from '@/utils/country-list';
import {
  useGeolocationQuery,
  usePlaceTrialOrderMutation,
  type AccountType,
  type RegistrationInput,
} from '@graphql/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from '@tanstack/react-router';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { TrialInfo } from './components/trial-info';
import { PersonalInfoForm } from './steps/personal-info-form';
import { SelectTrialAccountSize } from './steps/select-trial-account-size';

export interface CartData {
  accountType?: AccountType;
  initialBalance?: number;
}

const challengeSchema = z.object({
  firstName: z.string().min(1, { message: 'First name is required' }),
  lastName: z.string().min(1, { message: 'Last name is required' }),
  email: z.string().min(1, { message: 'Email is required' }).email(),
  city: z.string().min(1, { message: 'City is required' }),
  street: z.string().min(1, { message: 'Street is required' }),
  zip: z.string().min(1, { message: 'Postal code is required' }),
  countryCode: z.string().min(1, { message: 'Country is required' }),
  password: z.string().optional(),
  termsPolicy: z.literal<boolean>(true),
  refundPolicy: z.literal<boolean>(true),
});

type Challenge = z.infer<typeof challengeSchema>;

export function Trial(): JSX.Element {
  const { t } = useTranslation();
  const [placeTrialOrderMutation, { loading }] = usePlaceTrialOrderMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { setToken } = useContext(CredentialsContext);
  const user = useCurrentUser();
  const { data: geolocation } = useGeolocationQuery();
  const navigate = useNavigate();
  const [initialBalance, setInitialBalance] = useState<number>();
  const { identify } = useAnalytics();

  const methods = useForm<Challenge>({
    resolver: zodResolver(challengeSchema),
    mode: 'onChange',
  });
  const { handleSubmit, setValue, watch } = methods;
  const watchCountry = watch('countryCode');

  useEffect(() => {
    if (geolocation?.geolocation.country) {
      setValue('countryCode', geolocation.geolocation.country);
    }
    if (geolocation?.geolocation.city) {
      setValue('city', geolocation.geolocation.city);
    }

    if (geolocation?.geolocation.zipCode) {
      setValue('zip', geolocation.geolocation.zipCode);
    }
  }, [geolocation, setValue]);

  async function placeOrder(): Promise<void> {
    const {
      password,
      refundPolicy: _refundPolicy,
      termsPolicy: _termsPolicy,
      ...data
    } = methods.getValues();
    const registrationData: RegistrationInput | null = !user?.me
      ? {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          countryCode: data.countryCode,
          password: password ?? '',
        }
      : null;

    const orderAddress = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      city: data.city,
      street: data.street,
      zip: data.zip,
      countryCode: data.countryCode,
    };

    try {
      const response = await placeTrialOrderMutation({
        variables: {
          address: orderAddress,
          registrationData,
          balance: Number(initialBalance),
        },
      });
      if (!user?.me) {
        setToken(String(response.data?.placeTrialOrder.token));
        if (response?.data?.placeTrialOrder?.user?.id) {
          identify(response?.data?.placeTrialOrder?.user?.id, {
            first_name: response?.data?.placeTrialOrder?.user?.firstName,
            last_name: response?.data?.placeTrialOrder?.user?.lastName,
            email: response?.data?.placeTrialOrder?.user?.email,
          });
        }
      }
      const login = response.data?.placeTrialOrder.brokerAccount?.login ?? '';
      navigate({
        to: `/dashboard/accounts/detail/$account-id`,
        params: { 'account-id': login },
      });
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar({
          message: e.message,
          variant: 'error',
        });
      }
    }
  }

  return (
    <FormProvider {...methods}>
      <div className="mx-auto w-full space-y-8">
        <TrialInfo />
        <SelectTrialAccountSize
          onChange={(e) => {
            return new Promise((resolve) => {
              setInitialBalance(e);
              resolve();
            });
          }}
        />
        <PersonalInfoForm
          isLoggedIn={Boolean(user?.me)}
          geolocation={geolocation}
          watchCountry={watchCountry}
        />
        <Button
          // @ts-expect-error - ???
          disabled={!initialBalance || isBannedCountry(watchCountry)}
          className="w-full"
          type="submit"
          loading={loading}
          onClick={() => {
            handleSubmit(async () => {
              await placeOrder();
            })();
          }}
        >
          {t('Submit')}
        </Button>
      </div>
    </FormProvider>
  );
}
