import { Loading } from '@/components/base/loading';
import { createContext, useContext, useEffect, type ReactNode } from 'react';
import { useIntercom } from 'react-use-intercom';
import { type GetViewerQuery } from '../graphql';
import { useAuth } from './auth';

export const UserContext = createContext<GetViewerQuery>({} as GetViewerQuery);

export function UserProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const { data, loading, isAuthenticated } = useAuth();
  const { update } = useIntercom();

  useEffect(() => {
    if (data && isAuthenticated) {
      if (data.me.id) {
        const d = new Date(Date.parse(String(data?.me.createdAt)));
        update({
          email: data?.me.email,
          userId: data?.me.id,
          createdAt: d.getTime(),
          name: `${data?.me.firstName} ${data?.me.lastName}`,
        });
      }
    }
  }, [data, isAuthenticated, update]);
  return (
    <UserContext.Provider value={data!}>
      {/*HOTFIX*/}
      {!loading ? children : <Loading />}
    </UserContext.Provider>
  );
}

export function useCurrentUser(): GetViewerQuery {
  return useContext(UserContext);
}
