import { cn } from '@/utils';
import { faCheck, faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva, type VariantProps } from 'class-variance-authority';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'usehooks-ts';
import { Button } from './button';

export const CopyToolVariants = cva(
  'flex w-full items-center justify-between rounded-default border border-default-gray-800 bg-white p-3 text-sm text-default-gray-950 dark:bg-default-gray-950 dark:text-default-gray-400',
  {
    variants: {
      variant: {
        default: 'text-black',
        secondary:
          'dark:bg-default-gray-950 dark:text-white text-default-gray-950 bg-default-gray-100 border border-default-gray-200 dark:border-default-gray-800',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

interface CopyToolProps extends VariantProps<typeof CopyToolVariants> {
  text: string;
  label?: string;
  hidden?: boolean;
  className?: string;
}

export function CopyTool({
  text,
  label,
  variant,
  hidden = false,
  className,
}: CopyToolProps): JSX.Element {
  const [_copiedText, copy] = useCopyToClipboard();
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();
  const [show, setShow] = useState(!hidden);

  function handleCopy(): void {
    return void (async () => {
      await copy(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 5000);
    })();
  }

  return (
    <div className="flex w-full flex-col gap-1">
      {label && (
        <span className="text-sm text-default-gray-950 dark:text-white">
          {label}
        </span>
      )}
      <div className={cn(CopyToolVariants({ variant }), className)}>
        <div className="flex w-fit gap-3">
          {hidden && (
            <button
              data-testid="eye-button"
              type="button"
              onClick={() => {
                setShow((prev) => !prev);
              }}
            >
              <FontAwesomeIcon fixedWidth icon={show ? faEyeSlash : faEye} />
            </button>
          )}

          <span className="text-default-gray-900 dark:text-white">
            {' '}
            {show ? text : '•'.repeat(text.length)}
          </span>
        </div>
        <Button
          data-testid="copy-button"
          className="flex items-center gap-1 py-[6px] font-bold"
          type="button"
          onClick={handleCopy}
          size="xsmall"
        >
          <span className="block text-white">
            {copied ? (
              <FontAwesomeIcon
                data-testid="icon-check"
                className="h-4 w-4 text-white"
                icon={faCheck}
              />
            ) : (
              t('copy.copy')
            )}
          </span>
        </Button>
      </div>
    </div>
  );
}

export default CopyTool;
