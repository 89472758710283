import {
  createContext,
  useContext,
  useEffect,
  useState,
  type ReactNode,
} from 'react';
import AppConfig from '../global.config';

interface AppConfigProps {
  AppConfig: AppConfig;
  isDark: boolean;
  toggleTheme: () => void;
}

const AppConfigContext = createContext<AppConfigProps | undefined>(undefined);

export function AppConfigProvider({
  children,
  addTheme = true,
}: {
  children: ReactNode;
  addTheme?: boolean;
}): JSX.Element {
  // const [isDark, setIsDark] = useState<boolean>(
  //   localStorage.theme === 'dark' ||
  //     (!('theme' in localStorage) &&
  //       window.matchMedia('(prefers-color-scheme: dark)').matches),
  // );
  const [isDark, setIsDark] = useState<boolean>(
    localStorage.theme === 'dark' || true,
  );

  useEffect(() => {
    if (!addTheme) return;
    if (isDark) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isDark]);

  function toggleTheme(): void {
    if (isDark) {
      setIsDark(false);
      localStorage.setItem('theme', 'light');
      document.body.classList.remove('dark');
    } else {
      setIsDark(true);
      localStorage.setItem('theme', 'dark');
      document.body.classList.add('dark');
    }
  }

  return (
    <AppConfigContext.Provider value={{ AppConfig, toggleTheme, isDark }}>
      {children}
    </AppConfigContext.Provider>
  );
}

export function useAppConfig(): AppConfigProps {
  const context = useContext(AppConfigContext);
  if (!context) {
    throw new Error('useAppConfig must be used within an AppConfigProvider');
  }
  return context;
}
