import { PageHeading } from '@/components/base/page-heading';
import { JoinSocialsSection } from '@/monevis-platform/components/(authenticated)/calendar/help-section';
import { useCurrentUser } from '@/monevis-platform/context/current-user-context';
import { useTranslation } from 'react-i18next';
import NewChallenge from './new-challenge';

export default function DashboardWithoutChallenge(): JSX.Element {
  const data = useCurrentUser();
  const { t } = useTranslation();

  return (
    <div className="flex h-full flex-col gap-4">
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-2">
          <div className="text-xl font-medium dark:text-white">
            {t('dashboard.greeting')}, {data?.me?.firstName}{' '}
            {data?.me?.lastName}
          </div>
          <div className="text-sm dark:text-default-gray-400">
            {t('dashboard.underTitle')}
          </div>
        </div>

        <div className="rounded-xl bg-blue-outline/15 px-10 py-3 text-sm font-semibold text-blue-outline">
          {t('dashboard.bluePill')}
        </div>
      </div>

      <div className="flex h-full flex-col justify-between gap-5">
        <NewChallenge />

        <JoinSocialsSection>
          <PageHeading subheading={t('dashboard.subheading')}>
            {t('dashboard.socialsText')}
          </PageHeading>
        </JoinSocialsSection>
      </div>
    </div>
  );
}
