import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import { usePageLayout } from '@/context/layout-context';
import { AccountDetailContent } from '@/monevis-platform/components/(authenticated)/account-detail/account-detail-content';
import { DetailHead } from '@/monevis-platform/components/(authenticated)/account-detail/head';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/accounts/$account-id',
)({
  component: () => (
    <PageWrapper title="Account detail - Monevis">
      <AccountDetailPage />
    </PageWrapper>
  ),
});

function AccountDetailPage(): JSX.Element {
  const { t } = useTranslation();
  usePageLayout({ title: t('accountId.title') });
  const { 'account-id': accountId } = Route.useParams();

  return (
    <ScreenContainer>
      <DetailHead accountId={accountId} />
      <AccountDetailContent accountId={accountId} />
    </ScreenContainer>
  );
}

export default AccountDetailPage;
