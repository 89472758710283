import { cn } from '@/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const labelVariants = cva(
  'p-2 w-fit text-xs rounded-lg h-fit my-auto transition-all duration-200',
  {
    variants: {
      variant: {
        default: 'bg-primary/15 border border-primary text-primary',
        green: 'bg-default-green/10 text-default-green',
        gray: 'bg-default-gray-200/30 dark:text-default-gray-300 text-default-gray-500',
        red: 'bg-danger/50 border-red-800 border dark:text-white text-white',
      },
      type: {
        solid: '',
        outlined: 'bg-transparent border',
      },
    },
    compoundVariants: [
      {
        variant: 'default',
        type: 'solid',
        className: 'bg-primary/15 text-primary',
      },
      {
        variant: 'default',
        type: 'outlined',
        className: 'border-primary text-primary',
      },
      {
        variant: 'green',
        type: 'solid',
        className: 'bg-default-green/15 text-white',
      },
      {
        variant: 'green',
        type: 'outlined',
        className: 'border-default-green text-default-green',
      },
      {
        variant: 'gray',
        type: 'solid',
        className: 'bg-default-gray-500/30 text-white',
      },
      {
        variant: 'gray',
        type: 'outlined',
        className: 'border-default-gray-500 text-default-gray-500',
      },
    ],
    defaultVariants: {
      variant: 'default',
      type: 'solid',
    },
  },
);

export interface LabelProps extends VariantProps<typeof labelVariants> {
  className?: string;
  children: React.ReactNode;
}

function Label({
  variant,
  type,
  className,
  children,
}: LabelProps): JSX.Element {
  return (
    <div className={cn(labelVariants({ variant, type, className }))}>
      {children}
    </div>
  );
}

export type VariantType = 'default' | 'green' | 'gray' | 'red';

export { Label, labelVariants };
