import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import { usePageLayout } from '@/context/layout-context';
import { AccountRestartPage } from '@/monevis-platform/components/(authenticated)/account-restart';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/accounts/restart/$account-id',
)({
  component: () => (
    <PageWrapper title="Account Restart">
      <AccountRestart />
    </PageWrapper>
  ),
});

function AccountRestart(): JSX.Element {
  usePageLayout({ title: 'Account Restart' });
  const { 'account-id': accountId } = Route.useParams();
  return (
    <ScreenContainer>
      <AccountRestartPage accountId={accountId} />
    </ScreenContainer>
  );
}
