import type { SVGProps } from 'react';

export default function SvgRiseNoLabel(
  props: SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#5E59EC"
        d="M11.973 0C5.385 0 0 5.376 0 12s5.337 12 11.973 12c6.635 0 11.973-5.376 11.973-12S18.608 0 11.973 0m3.414 18-.048-7.488L7.886 18H4.23l9.377-9.36h-3.414a1.927 1.927 0 0 0-1.924 1.92c0 .432.145.864.385 1.152l-1.827 1.824a4.55 4.55 0 0 1-1.154-3.024A4.51 4.51 0 0 1 10.194 6h7.79v11.952z"
      />
    </svg>
  );
}
