import type { SVGProps } from 'react';

export default function SvgCryptoCurrency(
  props: SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        stroke={props.stroke ?? '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m16.375 12.375-.625.375-1.25.75-1.471.883a2 2 0 0 1-2.058 0L9.5 13.5l-1.25-.75-.625-.375m8.75 3.5-.625.375-1.25.75-1.471.883a2 2 0 0 1-2.058 0L9.5 17l-1.25-.75-.625-.375m8.66-6.946-3.256 1.954a2 2 0 0 1-2.058 0L7.715 8.929a.5.5 0 0 1 0-.858l3.256-1.954a2 2 0 0 1 2.058 0l3.256 1.954a.5.5 0 0 1 0 .858M12 22c5.523 0 10-4.477 10-10v0c0-5.523-4.477-10-10-10v0C6.477 2 2 6.477 2 12v0c0 5.523 4.477 10 10 10"
      />
    </svg>
  );
}
