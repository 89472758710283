import { AuthLayout } from '@/components/(unathenticated)/layout/auth.layout';
import { PageWrapper } from '@/components/base/page-wrapper';
import { ChangePassword } from '@/monevis-platform/components/(unauthenticated/change-password';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute(
  '/(unathenticated)/auth/_layout/change-password',
)({
  component: () => (
    <PageWrapper title="Reset password - Monevis">
      <ResetPasswordPage />
    </PageWrapper>
  ),
});

function ResetPasswordPage(): JSX.Element {
  const { t } = useTranslation();
  return (
    <AuthLayout
      sideContent={
        <div className="absolute left-0 top-0 h-full w-full max-w-[50vw] pl-20 pt-32 text-[35px] text-primary">
          <div className="max-w-[528px]">
            <h1 className="font-normal text-default-gray-950">
              {t('resetPasswordPage.sideContentTitle')}
            </h1>
          </div>
        </div>
      }
    >
      <ChangePassword />
    </AuthLayout>
  );
}

export default ResetPasswordPage;
