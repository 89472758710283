import { Button } from '@/components/base/button';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/base/select';
import { CryptoCurrency, RiseNoLabel } from '@/components/icons';
import { useAppConfig } from '@/context/app-config-context';
import { cn } from '@/utils';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  type PaymentMethodDetailsFragment,
  WithdrawalPaymentMethod,
} from '@graphql/index';
import { Link } from '@tanstack/react-router';
import { cloneElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  type Crypto,
  cryptoDataMap,
} from './payout-method/crypto-details-form';

export function SelectPayoutMethod({
  eligible,
  paymentMethods,
  riseUnderMinAmount,
  cryptoAboveMaxAmount,
  riseMethodCreated,
}: {
  riseUnderMinAmount: boolean;
  cryptoAboveMaxAmount: boolean;
  eligible: boolean;
  paymentMethods: PaymentMethodDetailsFragment[];
  riseMethodCreated: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const watchPaymentMethod = watch('paymentMethodId') as string;
  const { isDark } = useAppConfig();

  return (
    <Select
      disabled={eligible}
      onValueChange={(value) => {
        setValue('paymentMethodId', value);
      }}
    >
      <SelectTrigger
        data-testid="select-method-trigger"
        size="sm"
        variant="secondary"
        className={cn({
          'border-danger dark:border-danger':
            errors.paymentMethodId && !watchPaymentMethod,
        })}
      >
        <SelectValue placeholder={t('payout.selectPayoutMethod')} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup
          data-testid="select-account-content"
          className="divide-y divide-default-gray-200 dark:divide-default-gray-800"
        >
          <SelectLabel className="flex items-center justify-end gap-2">
            <Link to="/dashboard/request-payout/method">
              <Button size="xsmall" variant="outlined">
                <span>{t('payout.addPayoutMethod')}</span>
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Link>
          </SelectLabel>

          {paymentMethods.map((item: PaymentMethodDetailsFragment) => {
            const paymentMethodMetadata = item.paymentMethodMetadata
              ? (JSON.parse(item.paymentMethodMetadata) as { network?: string })
              : {};
            const isCryptoMethod =
              item.paymentMethod === WithdrawalPaymentMethod.DirectCrypto;
            let icon: React.ReactElement | null = null;

            if (isCryptoMethod) {
              const networkIcon = paymentMethodMetadata?.network
                ? cloneElement(
                    cryptoDataMap[paymentMethodMetadata.network as Crypto]
                      ?.icon,
                    { width: 25, height: 25 },
                  )
                : undefined;

              icon = (networkIcon as React.ReactElement | null) ?? (
                <CryptoCurrency stroke={isDark ? '#fff' : '#1E1F25'} />
              );
            } else {
              icon = <RiseNoLabel />;
            }

            return (
              <SelectItem
                className="w-full"
                key={item.id}
                value={item.id ?? item.targetAddress ?? ''}
                data-testid={item.displayName}
              >
                <div className="flex w-full items-center justify-between gap-2">
                  {icon}
                  <div className="flex flex-col justify-start">
                    <span className="w-fit">{item.displayName}</span>
                    <span className="w-fit text-xs text-default-gray-500">
                      {item.targetAddress}
                    </span>
                  </div>
                </div>
              </SelectItem>
            );
          })}
        </SelectGroup>
      </SelectContent>
      {errors.paymentMethodId?.message && !watchPaymentMethod && (
        <span className="text-sm text-danger">
          {errors.paymentMethodId.message as string}
        </span>
      )}
      {riseUnderMinAmount && (
        <div
          data-testid="min-payout-error"
          className="mt-2 rounded-lg border border-danger bg-danger/50 px-4 py-1 text-white/80"
        >
          {t('payout.riseMinPayoutError')}
        </div>
      )}

      {cryptoAboveMaxAmount && (
        <div
          data-testid="min-payout-error"
          className="mt-2 rounded-lg border border-danger bg-danger/50 px-4 py-1 text-white/80"
        >
          {t('payout.cryptoMaxError')}
        </div>
      )}
      {!eligible && !riseMethodCreated && (
        <div
          data-testid="add-rise-method-request"
          className="rounded-lg text-sm text-yellow-300"
        >
          {t('payout.addRiseMethodRequest')}
        </div>
      )}
    </Select>
  );
}
