import React, { cloneElement, useState } from 'react';

export interface WizardStep {
  id: number;
  label: string;
  component: React.ReactNode;
}

export interface WizardProps<T> {
  steps: WizardStep[];
  onFinish: (values: T) => void;
  loading: boolean;
  className?: string;
}

export function Wizard<T>({
  steps,
  onFinish,
  loading,
  className,
}: WizardProps<T>): JSX.Element {
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsData, setStepsData] = useState<T | null>(null);

  const handleNext = (data: T): void => {
    setStepsData((prev) => {
      return { ...prev, ...data };
    });

    if (currentStep === steps.length - 1) {
      onFinish({ ...stepsData, ...data });
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = (): void => {
    setCurrentStep(Math.max(0, currentStep - 1));
  };

  return (
    <div className={className}>
      {cloneElement(steps[currentStep]?.component as React.ReactElement, {
        loading,
        handleNext,
        handleBack,
        currentStep,
        steps,
      })}
    </div>
  );
}
