import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import { usePageLayout } from '@/context/layout-context';
import { ImpersonationForm } from '@/monevis-platform/components/(authenticated)/impersonate';

import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/impersonate',
)({
  component: () => (
    <PageWrapper title="Impersonate">
      <ImpersonatePage />
    </PageWrapper>
  ),
});

function ImpersonatePage(): JSX.Element {
  usePageLayout({ title: 'Impersonate' });

  return (
    <ScreenContainer className="justify-between">
      <ImpersonationForm />
    </ScreenContainer>
  );
}

export default ImpersonatePage;
