import { PageHeading } from '@/components/base/page-heading';
import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import { usePageLayout } from '@/context/layout-context';
import { JoinSocialsSection } from '@/monevis-platform/components/(authenticated)/calendar/help-section';
import { CalendarTableWithData } from '@/monevis-platform/components/(authenticated)/calendar/table/calendar-table';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/calendar',
)({
  component: () => (
    <PageWrapper title="Calendar - Monevis">
      <CalendarPage />
    </PageWrapper>
  ),
});

function CalendarPage(): JSX.Element {
  const { t } = useTranslation();
  usePageLayout({ title: t('calendarPage.title') });

  return (
    <ScreenContainer className="justify-between">
      <CalendarTableWithData />
      <JoinSocialsSection>
        <PageHeading subheading={t('calendarPage.joinCommunitySubheading')}>
          {t('calendarPage.helpHeading')}
        </PageHeading>
      </JoinSocialsSection>
    </ScreenContainer>
  );
}

export default CalendarPage;
