import { LoadingScreen } from '@/components/loading-screen';
import { CredentialsContext } from '@/context/credentials-context';
import { useGetViewerQuery, type GetViewerQuery } from '@graphql/index';
import * as React from 'react';

export interface AuthContext {
  isAuthenticated: boolean;
  loading: boolean;
  data: GetViewerQuery | undefined;
}

export const AuthContext = React.createContext<AuthContext | null>(null);

export function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { hasToken } = React.useContext(CredentialsContext);
  const { data, loading } = useGetViewerQuery({
    fetchPolicy: 'network-only',
    skip: !hasToken,
    errorPolicy: 'all',
  });

  const isAuthenticated = Boolean(data?.me);
  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, data }}>
      {loading ? <LoadingScreen /> : children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContext {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
