import Analytics from 'analytics';
import { createContext, useContext, type ReactNode } from 'react';
import { type ChangeLocalePayload } from './analytics/events/change-locale';
import { type FailedPurchasePayload } from './analytics/events/failed-purchase';
import { type InitiatedGatewayPayload } from './analytics/events/initiated-gateway';
import { type LeadEventPayload } from './analytics/events/lead';
import { type OrderPayload } from './analytics/events/order';
import { type PurchasePayload } from './analytics/events/purchase';
import { type SelectedPaymentMethodPayload } from './analytics/events/selected-payment-method';
import { CIO } from './analytics/plugins/cio';
import { providerFacebookPixel } from './analytics/plugins/facebook';
import { googleEnhancedEcommerce } from './analytics/plugins/ga-enhanced';
import { googleAnalytics } from './analytics/plugins/ga-legacy';
import { providerSeznam } from './analytics/plugins/seznam';
import { providerTwitter } from './analytics/plugins/twitter';
// @ts-expect-error-- no types
import googleTagManager from '@analytics/google-tag-manager';

export const instance = Analytics({
  app: 'monevis',
  debug: true,
  plugins: [
    CIO(),
    googleAnalytics({
      measurementIds: ['G-XFVQ4V7Z3N'],
    }),
    googleEnhancedEcommerce(),
    providerFacebookPixel({
      name: 'facebook-pixel',
      pixel:
        (import.meta.env.VITE_FACEBOOKPIXEL_ID as string) ||
        window.ENV.FACEBOOKPIXEL_ID,
    }),
    providerFacebookPixel({
      name: 'facebook-pixel2',
      pixel: '1245717169644929',
    }),
    providerTwitter({ name: 'twitter' }),
    providerSeznam({ name: 'seznam' }),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call -- no types
    googleTagManager({
      containerId: 'GTM-MDZGRPX3',
    }),
  ],
});

export enum EventName {
  Lead = 'Lead',
  InitiatedGateway = 'InitiatedGateway',
  SelectedPaymentMethod = 'SelectedPaymentMethod',
  Purchase = 'Purchase',
  FailedPurchase = 'FailedPurchase',
  Order = 'Order',
  ChangeLocale = 'ChangeLocale',
  InitiateCheckout = 'InitiateCheckout',
}

export interface EventMap {
  [EventName.Lead]: LeadEventPayload;
  [EventName.InitiatedGateway]: InitiatedGatewayPayload;
  [EventName.SelectedPaymentMethod]: SelectedPaymentMethodPayload;
  [EventName.Purchase]: PurchasePayload;
  [EventName.FailedPurchase]: FailedPurchasePayload;
  [EventName.Order]: OrderPayload;
  [EventName.ChangeLocale]: ChangeLocalePayload;
  [EventName.InitiateCheckout]: object;
}

export function track<K extends keyof EventMap>(
  eventName: K,
  payload: EventMap[K],
): void {
  void instance.track(eventName, payload);
}

export function identify(
  user: string,
  traits: { email: string; first_name?: string; last_name?: string },
): void {
  void instance.identify(user, traits);
}

interface AnalyticsContextProps {
  page: typeof instance.page;
  track: typeof track;
  identify: typeof identify;
}

const AnalyticsContext = createContext<AnalyticsContextProps>({
  page: (...props) => instance.page(...props),
  track: (_event, _payload) => false,
  identify: (_user, _traits) => false,
});

export const useAnalytics = (): AnalyticsContextProps => {
  return useContext(AnalyticsContext);
};

export function AnalyticsContextProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return (
    <AnalyticsContext.Provider
      value={{
        page: instance.page,
        track,
        identify,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
}
