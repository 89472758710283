import { Button } from '@/components/base/button';
import { PageHeading } from '@/components/base/page-heading';
import WidgetWithImage from '@/components/base/widget-with-image';
import { useCurrentUser } from '@/monevis-platform/context/current-user-context';
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@tanstack/react-router';
import { Trans, useTranslation } from 'react-i18next';
import { PayoutsTableWithData } from './payouts-table';

export function Payouts(): JSX.Element {
  const data = useCurrentUser();
  const { t } = useTranslation();
  const { firstName, lastName } = data.me;

  return (
    <div>
      <div className="flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
        <PageHeading subheading={t('payout.subheading')}>
          <Trans
            i18nKey="payout.welcomeMessage"
            values={{ name: `${firstName} ${lastName}` }}
          />
        </PageHeading>
        <Link to="/dashboard/request-payout">
          <Button
            className="w-full sm:w-fit"
            icon={<FontAwesomeIcon icon={faCreditCard} />}
          >
            {t('payout.requestButton')}
          </Button>
        </Link>
      </div>
      <div className="my-8">
        <WidgetWithImage
          img="/images/payout-certificates.png"
          header={<>{t('payout.widgetHeader')}</>}
          subheader={t('payout.widgetSubheader')}
        >
          <Button
            as="link"
            className="dark:text-white"
            href="https://certificates.monevis.com/?type=WITHDRAWAL&limit=12"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('payout.showCertificatesButton')}
          </Button>
        </WidgetWithImage>
      </div>

      <PayoutsTableWithData />
    </div>
  );
}
