import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import { usePageLayout } from '@/context/layout-context';
import DashboardAccount from '@/monevis-platform/components/(authenticated)/dashboard-account';
import DashboardWithoutChallenge from '@/monevis-platform/components/(authenticated)/dashboard/dashboard-without-challenge';
import { ChallengeStatus, useGetBrokerAccountsQuery } from '@graphql/index';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/(authenticated)/dashboard/_layout/home')(
  {
    component: () => (
      <PageWrapper title="Dashboard - Monevis">
        <HomePage />
      </PageWrapper>
    ),
  },
);

function HomePage(): JSX.Element {
  const { t } = useTranslation();
  usePageLayout({ title: t('homePage.title') });
  const { data } = useGetBrokerAccountsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const brokerAccounts = data?.me?.brokerAccounts ?? [];
  const activeAccounts = brokerAccounts?.filter(
    (account) => account.challengeStatus === ChallengeStatus.Evaluation,
  );

  const showAccounts = brokerAccounts.length > 0 && activeAccounts.length > 0;

  return (
    <ScreenContainer>
      {showAccounts ? <DashboardAccount /> : <DashboardWithoutChallenge />}
    </ScreenContainer>
  );
}

export default HomePage;
