import Header from '@/components/(authenticated)/layout/header';
import useAffiliateCode from '@/hooks/use-affiliate-code';
import { SidebarWithItems } from '@/monevis-platform/components/(authenticated)/layout/sidebar';
import { useAuth } from '@/monevis-platform/context/auth';
import { UserProvider } from '@/monevis-platform/context/current-user-context';
import { cn } from '@/utils';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import Styles from './styles.module.css';

export const Route = createFileRoute('/_layout')({
  component: LayoutComponent,
});

function LayoutComponent(): JSX.Element {
  const { data } = useAuth();
  useAffiliateCode();

  return (
    <UserProvider>
      <div
        className={cn(
          Styles.styles,
          'h-full bg-default-gray-50 transition-all duration-300 dark:bg-dark sm:p-5',
        )}
      >
        <div style={{ gridArea: 'header', margin: '0 0 26px 0' }}>
          <Header />
        </div>
        {data?.me && (
          <div
            style={{ gridArea: 'sidebar', margin: '0 26px 0 0' }}
            className="hidden lg:block"
          >
            <SidebarWithItems />
          </div>
        )}

        <div
          className="mb-12 px-5 sm:mb-0 sm:px-0"
          style={{ gridArea: 'main', overflowY: 'auto' }}
        >
          <Outlet />
        </div>
      </div>
    </UserProvider>
  );
}
