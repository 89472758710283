import Header from '@/components/(authenticated)/layout/header';
import { SidebarWithItems } from '@/monevis-platform/components/(authenticated)/layout/sidebar';
import {
  useCurrentUser,
  UserProvider,
} from '@/monevis-platform/context/current-user-context';
import { cn } from '@/utils';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import Styles from '../../styles.module.css';

export const Route = createFileRoute('/(global)/order/_layout')({
  component: LayoutComponent,
});

//Auth Layout - generic
function LayoutComponent(): JSX.Element {
  return (
    <UserProvider>
      <OrderPageWithLayout />
    </UserProvider>
  );
}

function OrderPageWithLayout(): JSX.Element {
  const user = useCurrentUser();

  if (user?.me) {
    return (
      <div
        className={cn(
          Styles.styles,
          'h-full bg-default-gray-50 transition-all duration-300 dark:bg-dark sm:p-5',
        )}
      >
        <div style={{ gridArea: 'header', margin: '0 0 26px 0' }}>
          <Header />
        </div>
        {user?.me && (
          <div
            style={{ gridArea: 'sidebar', margin: '0 26px 0 0' }}
            className="hidden lg:block"
          >
            <SidebarWithItems />
          </div>
        )}

        <div
          className="mb-12 px-5 sm:mb-0 sm:px-0"
          style={{ gridArea: 'main', overflowY: 'auto' }}
        >
          <Outlet />
        </div>
      </div>
    );
  }

  return (
    <div className="h-full bg-default-gray-50 transition-all duration-300 dark:bg-dark sm:p-5">
      <div className="flex flex-col gap-4">
        <Header />
        <Outlet />
      </div>
    </div>
  );
}
