import { useRouter } from '@tanstack/react-router';
import { useEffect } from 'react';

const AFFILIATE_CODE_KEY = 'affiliateCode';

type UseAffiliateCodeHook = () => {
  affiliateCode: string | null;
  setAffiliateCode: (code: string) => void;
};

const useAffiliateCode: UseAffiliateCodeHook = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- .
  const { search }: { search: { ref?: string } } = useRouter().latestLocation;

  useEffect(() => {
    const urlRef = search.ref;
    if (urlRef) {
      localStorage.setItem(AFFILIATE_CODE_KEY, urlRef);
    }
  }, [search]);

  const affiliateCode =
    search.ref ?? localStorage.getItem(AFFILIATE_CODE_KEY) ?? null;

  const setAffiliateCode = (code: string): void => {
    localStorage.setItem(AFFILIATE_CODE_KEY, code);
  };

  return { affiliateCode, setAffiliateCode };
};

export default useAffiliateCode;
