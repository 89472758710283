import { PageWrapper } from '@/components/base/page-wrapper';
import Section from '@/components/base/section';
import { usePageLayout } from '@/context/layout-context';
import { SettingsSidebar } from '@/monevis-platform/components/(authenticated)/settings-layout/sidebar';
import { Outlet, createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/_layout-settings',
)({
  component: () => (
    <PageWrapper title="Settings - Monevis">
      <LayoutComponent />
    </PageWrapper>
  ),
});

function LayoutComponent(): JSX.Element {
  const { t } = useTranslation();
  usePageLayout({ title: t('layoutSettings.title') });

  return (
    <Section
      className="h-full overflow-auto p-0 dark:bg-default-gray-950/55 sm:gap-0 sm:p-0 md:flex-row md:overflow-hidden"
      variant="secondary"
    >
      <SettingsSidebar />
      <div className="relative h-full w-full md:overflow-y-auto">
        <Outlet />
      </div>
    </Section>
  );
}
