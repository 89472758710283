import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import AppConfig from '../global.config';
import { CredentialsContext } from './credentials-context';

const options = {
  uri: AppConfig.GRAPHQL_ENDPOINT,
  credentials: 'same-origin',
};

// BatchHttpLink doesn't work with MSW
const link = new HttpLink(options);
interface ApolloContextType {
  apolloClient: ApolloClient<unknown> | null;
}

export const ApolloContext = createContext<ApolloContextType>({
  apolloClient: null,
});

export function ApolloContextProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const { jwtToken, clearToken } = useContext(CredentialsContext);
  const { enqueueSnackbar } = useSnackbar();
  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: jwtToken ? `Bearer ${jwtToken}` : '',
      },
    }));

    return forward(operation);
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, response }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (message === 'Unauthorized') {
            clearToken();
          } else {
            Sentry.withScope((scope) => {
              scope.setTag('kind', operation.operationName);
              scope.setExtra('query', JSON.stringify(operation.query));
              scope.setExtra('variables', JSON.stringify(operation.variables));
              scope.setExtra('response', JSON.stringify(response));

              if (path) {
                // We can also add the path as breadcrumb
                scope.addBreadcrumb({
                  category: 'Query',
                  // eslint-disable-next-line -- safe
                  message: path?.join(' > '),
                  level: 'debug',
                });
              }
              Sentry.captureException(
                new Error(`[GQL]: ${operation.operationName}`),
                {
                  extra: { locations, path, message },
                },
              );
            });
          }
        });
      if (networkError) {
        enqueueSnackbar({
          message: `[Network error]: ${(networkError as Error).message}`,
          variant: 'error',
        });
      }
    },
  );

  const client = useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache(),
        link: ApolloLink.from([errorLink, authMiddleware, link]),
      }),
    [authMiddleware],
  );
  return (
    <ApolloProvider client={client}>
      <>{children}</>
    </ApolloProvider>
  );
}
