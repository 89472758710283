import { cn } from '@/utils';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@tanstack/react-router';
import { type ReactNode } from 'react';
import { NotificationBadge } from '../notification-badge';

export interface MenuItemProps {
  to?: string;
  children: ReactNode;
  icon?: IconProp;
  isOpen?: boolean;
  notificationCount?: number;
  active?: boolean;
  className?: string;
  onClick?: () => void;
  tabIndex?: number;
}

export default function MenuItem({
  to,
  children,
  icon,
  isOpen = true,
  notificationCount,
  active = false,
  className,
  onClick,
  ...props
}: MenuItemProps): JSX.Element {
  return (
    <Link
      onClick={onClick}
      to={to}
      key={to}
      {...props}
      className={cn(
        'group flex w-full items-center justify-between gap-4 whitespace-nowrap px-6 py-[10px] text-sm text-default-gray-900 transition-all duration-200 hover:text-black dark:text-default-gray-400 dark:hover:text-white',
        {
          'bg-default-gray-100 font-bold text-black hover:bg-default-gray-100 hover:font-bold dark:bg-default-gray-800 dark:text-white dark:hover:bg-default-gray-800':
            active,
        },
        className,
      )}
      {...props}
    >
      <div className="relative flex items-center justify-start gap-4">
        {icon && (
          <FontAwesomeIcon
            data-testid="menu-item-icon"
            icon={icon}
            className="h-[24px] w-[24px] min-w-[24px]"
          />
        )}

        {Boolean(notificationCount) && !isOpen && (
          <div className="absolute right-0 top-0 -translate-y-2 translate-x-2 items-center">
            <NotificationBadge
              size="sm"
              notificationsCount={notificationCount ?? 0}
            />
          </div>
        )}

        {isOpen && <span className="leading-3">{children}</span>}
      </div>

      {Boolean(notificationCount) && isOpen && (
        <NotificationBadge notificationsCount={notificationCount ?? 0} />
      )}
    </Link>
  );
}
