import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import { usePageLayout } from '@/context/layout-context';
import { Metatrader } from '@/monevis-platform/components/(authenticated)/terminal/metaterminal';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/accounts/terminal',
)({
  component: () => (
    <PageWrapper title="Terminal - Monevis">
      <TerminalPage />
    </PageWrapper>
  ),
});

function TerminalPage(): JSX.Element {
  const { t } = useTranslation();
  usePageLayout({ title: t('terminalAccounts.title') });
  const { 'account-id': accountId } = Route.useParams();
  return (
    <ScreenContainer>
      <Metatrader accountId={accountId} />
    </ScreenContainer>
  );
}
