import { Button } from '@/components/base/button';
import { Dialog, DialogContent } from '@/components/base/dialog';
import { PageHeading } from '@/components/base/page-heading';
import Section from '@/components/base/section';
import { Confetti } from '@/monevis-platform/components/order/components/confetti';
import { Trans, useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

export function SuccessfulRequest({
  open,
  onClose,
  amount,
}: {
  open: boolean;
  amount: number;
  onClose: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const intl = useIntl();

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        data-testid="success-dialog"
        className="max-w-3xl gap-6 md:pb-0"
      >
        <Confetti />
        <div className="flex h-full w-full items-start justify-center">
          <Section
            variant="secondary"
            className="max-w-[440px] border-none dark:border-none"
          >
            <div className="flex flex-col items-center gap-9 text-center">
              <h1 className="text-5xl font-bold">{t('payout.title')} 🎉</h1>

              <PageHeading
                subheading={
                  <Trans
                    i18nKey="payout.subheadingSucces"
                    values={{
                      amount: intl.formatNumber(amount, {
                        currency: 'USD',
                        style: 'currency',
                        maximumFractionDigits: 2,
                      }),
                    }}
                    components={{}}
                  />
                }
                size="xxlarge"
              >
                {t('payout.heading')}
              </PageHeading>
              <div className="flex w-full items-center gap-4">
                <Button
                  data-testid="ok-button"
                  size="sm"
                  className="w-full"
                  onClick={() => {
                    onClose();
                  }}
                >
                  {t('payout.okButton')}
                </Button>
              </div>
            </div>
          </Section>
        </div>
      </DialogContent>
    </Dialog>
  );
}
